import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SvgBox from "components/svg-box";
import { StyledContent, StyledWrapper } from "./styles";
import { ERROR_PAGE_TITLE } from "lib/page-titles";

export default function ErrorPage() {
  return (
    <>
      <Helmet>
        <title>{ERROR_PAGE_TITLE}</title>
      </Helmet>

      <StyledWrapper>
        <div>
          <span />
          <span />
        </div>

        <StyledContent>
          <div>
            <div data-cloud>
              <SvgBox src="/assets/icons/error/ic_cloud.svg" component="img" />
              <SvgBox
                src="/assets/icons/error/ic_exclamation.svg"
                component="img"
              />
            </div>

            <h1>مشکلی پیش آمده است</h1>
            <p>بابت مشکل پیش آمده پوزش می طلبیم، لطفاً دوباره امتحان کنید</p>
          </div>

          <div>
            <Link to="/">بازگشت به کارتابل</Link>
          </div>
        </StyledContent>
      </StyledWrapper>
    </>
  );
}
