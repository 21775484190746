import PropTypes from "prop-types";
import { DialogContent } from "@mui/material";
import { StyledDialog } from "./styles";

export default function Modal({ open, toggle, children, ...rest }) {
  return (
    <StyledDialog open={open} onClose={() => toggle()} fullWidth {...rest}>
      {open && <DialogContent>{children}</DialogContent>}
    </StyledDialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
};
