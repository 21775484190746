import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    ".MuiDialogContent-root": {
      padding: "40px 40px",
    },
  },

  [theme.breakpoints.down("sm")]: {
    ".MuiDialog-paper": {
      margin: 16,
      width: "calc(100% - 32px)",
      ".MuiDialogContent-root": {
        padding: "16px 16px",
      },
    },
  },
}));
