import { styled } from "@mui/material/styles";

export const StyledSmallCard = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 10,
  padding: "24px 20px",
  boxShadow: "0px 4px 4px 0px #00000040",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  h4: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24,
  },
  div: {
    marginTop: "auto",
    a: {
      textDecoration: "underline",
      "&[data-disabled=true]": {
        pointerEvents: "none",
        color: "#7f7f7f",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    h4: {
      marginBottom: 0,
    },
    div: {
      marginRight: 16,
      textAlign: "left",
    },
  },
}));
