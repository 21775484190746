import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import Dropzone from "components/dropzone";
import Spin from "components/spin";
import useModal from "hooks/useModal";
import DeleteModal from "components/modal/common/delete-modal";

export default function RHKCustomDropzone({
  name,
  rules,
  children,
  onSuccess,
  ...other
}) {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  const [openDeleteModal, toggleDeleteModal] = useModal();

  return (
    <>
      <Dropzone
        onError={() => field.onChange(null)}
        onSuccess={(obj) => {
          field.onChange(obj);
          onSuccess?.(obj);
        }}
        {...other}
      >
        {({ mutation }) => (
          <Spin isLoading={mutation.isLoading}>
            {children({
              error: mutation.error,
              toggleDeleteModal,
            })}

            {error && (
              <FormHelperText error sx={{ textAlign: "right" }}>
                {error?.message || "این مقدار الزامی است"}
              </FormHelperText>
            )}
          </Spin>
        )}
      </Dropzone>

      <DeleteModal
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        onDelete={() => {
          field.onChange(null);
          toggleDeleteModal();
        }}
      >
        <div>Are you sure you want to remove file?</div>
      </DeleteModal>
    </>
  );
}

RHKCustomDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  children: PropTypes.func.isRequired,
};
