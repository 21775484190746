import { Typography } from "@mui/material";
import PageWrapper from "components/page-wrapper";
import { DASHBOARD_PAGE_TITLE } from "lib/page-titles";
import Table from "components/table";
import { StyledContent } from "./styles";
import useUILogic from "./logic";

export default function DashboardPage() {
  const {
    user,
    //
    dataEvaluations,
    columnsEvaluations,
    isLoadingEvaluations,
    //
    dataInspections,
    columnsInspections,
    isLoadingInspections,
  } = useUILogic();

  return (
    <PageWrapper pageTitle={DASHBOARD_PAGE_TITLE} title="کارتابل">
      <Typography variant="h2" mb={4} style={{ color: "#686868" }}>
        درخواست های ارزیابی
      </Typography>

      <StyledContent>
        <Table
          columns={columnsEvaluations}
          data={dataEvaluations}
          gridTemplateColumns="56px 32px minmax(125px, 1fr) 104px minmax(125px, 1fr)"
          sx={{ padding: "24px 32px" }}
          isLoading={isLoadingEvaluations}
        />
      </StyledContent>

      {user?.active_role?.value !== "Evaluator" && (
        <>
          <div style={{ marginBottom: "56px" }} />

          <Typography variant="h2" mb={4} style={{ color: "#686868" }}>
            درخواست های بازرسی
          </Typography>

          <StyledContent>
            <Table
              columns={columnsInspections}
              data={dataInspections}
              gridTemplateColumns="56px 32px minmax(125px, 1fr) 104px minmax(125px, 1fr)"
              sx={{ padding: "24px 32px" }}
              isLoading={isLoadingInspections}
            />
          </StyledContent>
        </>
      )}
    </PageWrapper>
  );
}
