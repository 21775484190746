import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/material";

const SvgBox = forwardRef(
  ({ src, component = "span", sx, className, ...rest }, ref) => (
    <Box
      component={component}
      className={clsx("svg-box", className)}
      ref={ref}
      {...(component === "img" && {
        src: `${process.env.PUBLIC_URL}${src}`,
      })}
      sx={{
        width: 24,
        height: 24,
        display: "inline-block",
        ...(component !== "img" && {
          bgcolor: "currentColor",
          mask: `url(${process.env.PUBLIC_URL}${src}) no-repeat center / contain`,
          WebkitMask: `url(${process.env.PUBLIC_URL}${src}) no-repeat center / contain`,
        }),
        ...sx,
      }}
      {...rest}
    />
  )
);

SvgBox.propTypes = {
  src: PropTypes.string.isRequired,
  component: PropTypes.string,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default SvgBox;
