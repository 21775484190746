import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { TextField } from "@mui/material";

export default function RHKTextField({ name, rules, onChange, ...rest }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  return (
    <TextField
      {...field}
      inputRef={ref}
      onChange={(e) => {
        field.onChange(e);
        onChange?.(e);
      }}
      error={!!error}
      helperText={error ? error?.message || "این مقدار الزامی است" : ""}
      {...rest}
    />
  );
}

RHKTextField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
