import { Grid } from "@mui/material";
import { StyledSmallCard } from "./styles";
import { useRequestContext } from "../../request-context";

export default function TopSection() {
  const { requestData } = useRequestContext();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <StyledSmallCard>
          <h4>عنوان</h4>
          <div>{requestData?.title}</div>
        </StyledSmallCard>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledSmallCard>
          <h4>فایل شناسنامه</h4>
          <div>
            <a
              href={requestData?.document_file_url}
              data-disabled={!requestData?.document_file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              مشاهده
            </a>
          </div>
        </StyledSmallCard>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledSmallCard>
          <h4>کسب و کار</h4>
          <div>
            <a
              href={requestData?.customer_business_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {requestData?.customer_business_title}
            </a>
          </div>
        </StyledSmallCard>
      </Grid>
    </>
  );
}
