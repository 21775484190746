import { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, InputAdornment } from "@mui/material";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import RHKSelect from "../rhk-select";

export default function RHKSelectCity({ province, disabled, ...other }) {
  const [options, setOptions] = useState();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.resources.cities,
    onSuccess: (res) => {
      const t = res.data?.data?.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
      setOptions(t);
    },
  });

  return (
    <RHKSelect
      disabled={disabled || !province}
      options={options || []}
      onOpen={() => {
        if (!options?.length) mutate({ params: { province } });
      }}
      enableSearch
      selectOnFocus
      autoHighlight
      startAdornment={
        isLoading ? (
          <InputAdornment position="start">
            <CircularProgress color="inherit" size={20} />
          </InputAdornment>
        ) : null
      }
      {...other}
    />
  );
}

RHKSelectCity.propTypes = {
  province: PropTypes.string,
};
