import { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function useUILogic({ defaultData, onSubmit }) {
  const methods = useForm();

  useEffect(() => {
    if (defaultData) {
      methods.reset({
        province: defaultData.province,
        city: defaultData.city,
        postal_code: defaultData.postal_code || "",
        address: defaultData.address || "",
      });
    }
  }, [defaultData]);

  const onNext = (params) => {
    if (Object.keys(methods.formState.dirtyFields).length) {
      onSubmit({
        ...params,
        province: params.province?.value,
        city: params.city?.value,
      });
    } else {
      onSubmit(null);
    }
  };

  return {
    methods,
    onNext,
  };
}
