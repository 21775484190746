import { Helmet } from "react-helmet-async";
import { FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import RHKTextField from "components/forms/rhk-text-field";
import RHKPassword from "components/forms/rhk-password";
import { EMAIL_REGEX } from "lib/regex";
import { Panel_Routes } from "routes/path";
import useUILogic from "./logic";
import { Button, Grid, Typography } from "@mui/material";
import { LOGIN_PAGE_TITLE } from "lib/page-titles";
import RHKSelect from "components/forms/rhk-select";
import RHKCustomPatternFormat from "components/forms/rhk-custom-pattern-format";
import ConfirmModal from "components/modal/common/confirm-modal";
import { en2pr } from "lib/helpers";

export default function LoginPage() {
  const {
    methods,
    //
    formatted,
    setFormatted,
    //
    open,
    toggle,
    //
    onSubmit,
    onConfirm,
    isLoading,
  } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{LOGIN_PAGE_TITLE}</title>
      </Helmet>

      <Typography mb={6}>ورود به سامانه شبکه مهندسی</Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container flexDirection="column" rowSpacing={6}>
            {/* <Grid item>
              <RHKSelect
                name="role"
                label="ورود به عنوان"
                placeholder="نقش"
                rules={{ required: true }}
                disabled={isLoading}
                options={roleOptions}
              />
            </Grid> */}

            <Grid item>
              <RHKCustomPatternFormat
                name="mobile"
                format="#### ### ####"
                label="شماره همراه"
                placeholder="۰۹×× ××× ××××"
                disabled={isLoading}
                rules={{
                  required: true,
                  pattern: {
                    value: /^09[0-9]{9}/,
                    message: "فرمت شماره همراه اشتباه است",
                  },
                }}
                onChange={(e) => setFormatted(e.formattedValue)}
              />
            </Grid>

            <Grid item mb={6}>
              <Button variant="contained" sx={{ width: 129 }} type="submit">
                ورود
              </Button>
            </Grid>

            {/* <Grid item>
              <Typography variant="body2">
                <Link to={Panel_Routes.auth.register.evaluators}>
                  ثبت نام ارزیاب ها
                </Link>
              </Typography>
            </Grid> */}
          </Grid>
        </form>
      </FormProvider>

      <ConfirmModal
        open={open}
        toggle={toggle}
        onConfirm={onConfirm}
        isLoading={isLoading}
      >
        شماره همراه{" "}
        <div style={{ direction: "ltr", display: "inline" }}>
          {en2pr(methods.watch("mobile"))}
        </div>{" "}
        را تایید می کنید ؟
      </ConfirmModal>
    </>
  );
}
