import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";

export const StyledRoot = styled(AppBar)(({ theme }) => ({
  ".MuiToolbar-root": {
    display: "flex",
    alignItems: "center",
    ">div:first-of-type": {
      display: "flex",
      alignItems: "center",

      ">div": {
        marginRight: 64,
        p: {
          fontSize: 12,
          color: "#D7D7D7",
          marginTop: 4,
        },
      },
    },

    ">div:last-of-type": {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",

      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-end",
      },
    },
  },
}));
