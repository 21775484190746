import { StyledCard } from "./styles";

export default function ViewCard({ title, children, ...rest }) {
  return (
    <StyledCard {...rest}>
      <h4>{title}</h4>

      {children}
    </StyledCard>
  );
}
