import useUILogic from "./logic";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { StyledUploader } from "./styles";
import RHKCustomDropzone from "components/forms/rhk-custom-dropzone";
import { DEV_BACKEND_URL } from "routes/path";
import SvgBox from "components/svg-box";

export default function RegisterEvaluatorsForm5({
  prevStep,
  defaultData,
  onSubmit,
  isLoading,
}) {
  const { methods, onNext, successMessage } = useUILogic({
    defaultData,
    onSubmit,
  });

  const textfieldProps = (name) => {
    const value = methods.watch(name);
    return {
      placeholder: value ? "" : "برای بارگذاری کلیک کنید",
      InputProps: {
        readOnly: true,
        ...(value
          ? {
              startAdornment: (
                <a href={DEV_BACKEND_URL + value?.data?.url} target="_blank">
                  <SvgBox src="/assets/icons/common/ic_link.svg" />
                  <span>مشاهده فایل</span>
                </a>
              ),
              endAdornment: (
                <IconButton onClick={() => methods.setValue(name, null)}>
                  <SvgBox src="/assets/icons/common/ic_close.svg" />
                </IconButton>
              ),
            }
          : {}),
      },
    };
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onNext)}
        noValidate
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexDirection="column" rowSpacing={4}>
          <Grid item>
            <StyledUploader>
              <RHKCustomDropzone
                name="id_card_file"
                rules={{ required: true }}
                disabled={isLoading || methods.watch("id_card_file")}
                accept={{ "image/*": [] }}
                onSuccess={successMessage}
              >
                {() => (
                  <TextField
                    label="تصویر کارت ملی *"
                    {...textfieldProps("id_card_file")}
                  />
                )}
              </RHKCustomDropzone>
            </StyledUploader>
          </Grid>

          <Grid item>
            <StyledUploader>
              <RHKCustomDropzone
                name="resume_file"
                rules={{ required: true }}
                disabled={isLoading || methods.watch("resume_file")}
                accept={{ "application/pdf": [] }}
                onSuccess={successMessage}
              >
                {() => (
                  <TextField
                    label="فایل رزومه *"
                    {...textfieldProps("resume_file")}
                  />
                )}
              </RHKCustomDropzone>
            </StyledUploader>
          </Grid>

          <Grid item>
            <Typography variant="body2" textAlign="right">
              لطفا تمامی فایل های مربوط به رشته تحصیلی ، گواهینامه ها و تخصص ها
              را در یک فایل فشرده سازی کرده سپس اقدام به بارگذاری آن نمایید
            </Typography>
          </Grid>

          <Grid item>
            <StyledUploader>
              <RHKCustomDropzone
                name="other_document_file"
                rules={{ required: true }}
                disabled={isLoading || methods.watch("other_document_file")}
                accept={{
                  "application/zip": [".zip"],
                  "application/vnd.rar": [".rar"],
                }}
                onSuccess={successMessage}
              >
                {() => (
                  <TextField
                    label="مدارک مرتبط با تخصص *"
                    {...textfieldProps("other_document_file")}
                  />
                )}
              </RHKCustomDropzone>
            </StyledUploader>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-around" mt={"auto"} pt={3}>
          <Grid item>
            <Button
              variant="contained"
              color="gray"
              onClick={prevStep}
              disabled={isLoading}
            >
              مرحله قبل
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              ارسال برای بررسی
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
