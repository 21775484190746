import RHKTextField from "components/forms/rhk-text-field";
import useUILogic from "./logic";
import { Button, Grid } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import RHKCustomDropzone from "components/forms/rhk-custom-dropzone";
import { StyledUploader } from "./styles";
import SvgBox from "components/svg-box";
import RHKDatePicker from "components/forms/rhk-date-picker";
import RHKCustomPatternFormat from "components/forms/rhk-custom-pattern-format";
import Image from "components/image";
import { DEV_BACKEND_URL } from "routes/path";

export default function RegisterEvaluatorsForm1({
  prevStep,
  defaultData,
  onSubmit,
  isLoading,
}) {
  const { methods, onNext } = useUILogic({ defaultData, onSubmit });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onNext)}
        noValidate
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexDirection="column" rowSpacing={4}>
          <Grid item>
            <StyledUploader>
              <RHKCustomDropzone
                name="avatar"
                disabled={isLoading}
                accept={{ "image/*": [] }}
              >
                {() =>
                  methods.watch("avatar") ? (
                    <div data-profile-image>
                      <Image
                        src={
                          DEV_BACKEND_URL + methods.watch("avatar")?.data?.url
                        }
                        ratio="1/1"
                      />
                    </div>
                  ) : (
                    <div data-profile-upload>
                      <SvgBox src="/assets/icons/common/ic_upload_2.svg" />
                      <div>عکس پروفایل</div>
                    </div>
                  )
                }
              </RHKCustomDropzone>
            </StyledUploader>
          </Grid>

          <Grid item>
            <RHKTextField
              name="full_name"
              label="نام و نام خانوادگی *"
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <RHKCustomPatternFormat
              format="##########"
              name="national_id"
              label="کد ملی *"
              placeholder="۱۳۶×××۰۱۵۶"
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <RHKDatePicker
              name="birth_date"
              label="تاریخ تولد *"
              placeholder="--/--/----"
              rules={{ required: true }}
              disabled={isLoading}
              views={["year", "month", "day"]}
              openTo="year"
              maxDate={new Date()}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-around" mt={"auto"} pt={3}>
          <Grid item>
            <Button
              variant="contained"
              color="gray"
              onClick={prevStep}
              disabled={isLoading}
            >
              انصراف
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              مرحله بعد
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
