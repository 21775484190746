import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import useUILogic from "./logic";
import { Box, Grid, TextField, Typography } from "@mui/material";
import ConfirmModal from "components/modal/common/confirm-modal";
import { LOGIN_PAGE_TITLE, REGISTER_PAGE_TITLE } from "lib/page-titles";
import { Link } from "react-router-dom";
import { Panel_Routes } from "routes/path";
import RHKCustomPatternFormat from "components/forms/rhk-custom-pattern-format";
import { FormProvider } from "react-hook-form";
import Spin from "components/spin";

export default function AuthOtpPage() {
  const {
    mode,
    //
    countdown,
    setCountdown,
    //
    methods,
    onSubmit,
    isLoadingVerify,
    //
    onResend,
    isLoadingResend,
  } = useUILogic();

  return (
    <>
      <Helmet>
        <title>
          {mode === "login" ? LOGIN_PAGE_TITLE : REGISTER_PAGE_TITLE}
        </title>
      </Helmet>

      <Typography mb={4}>
        {mode === "login" ? "ورود به سامانه شبکه مهندسی" : "ثبت نام ارزیاب"}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container flexDirection="column" rowSpacing={4}>
            <Grid item>
              <Typography variant="caption" color="text.disabled">
                لطفا کد ۶ رقمی ارسال شده به شماره همراه را در باکس زیر وارد
                نمایید.
              </Typography>
            </Grid>

            <Grid item>
              <RHKCustomPatternFormat
                name="otp"
                format="######"
                label="کد پیامک"
                placeholder="××××××"
                disabled={isLoadingVerify}
                rules={{ required: true }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "4px",
                }}
              >
                <Typography variant="body2" p={"4px"}>
                  کد را دریافت نکرده اید ؟
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{
                    cursor: countdown === 0 && "pointer",
                    borderRadius: "4px",
                  }}
                  onClick={() => onResend()}
                  component={Spin}
                  isLoading={isLoadingResend}
                  size={20}
                  p={"4px"}
                >
                  تلاش مجدد ({countdown})
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <LoadingButton
                variant="contained"
                loading={isLoadingVerify}
                type="submit"
              >
                تایید کد پیامک
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      {mode !== "login" && (
        <Typography variant="body2" mt={5} mb={4}>
          حساب کاربری دارید ؟{" "}
          <Link to={Panel_Routes.auth.login.index}>ورود به شبکه مهندسی</Link>
        </Typography>
      )}
    </>
  );
}
