import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import useModal from "hooks/useModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

export default function useUILogic() {
  const navigate = useNavigate();
  const methods = useForm();

  // save formatted number
  const [formatted, setFormatted] = useState();

  // modal confirm
  const [open, toggle] = useModal();

  // submit
  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.register,
    method: "post",
  });

  const onSubmit = () => {
    toggle();
  };

  const onConfirm = async () => {
    const mobile = methods.getValues("mobile");

    try {
      const res = await mutate({ data: { params: { mobile } } });

      navigate(
        `${Panel_Routes.auth.otp}?otp_id=${res.data?.data?.otp_id}&mode=regeval`
      );
    } catch (error) {
      toggle();
    }
  };

  return {
    methods,
    //
    formatted,
    setFormatted,
    //
    open,
    toggle,
    //
    onSubmit,
    onConfirm,
    isLoading,
  };
}
