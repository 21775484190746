import { styled, keyframes } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

export const StyledSnack = styled(SnackbarProvider)(({ theme }) => ({
  borderRadius: "8px !important",

  "&>div:last-of-type": {
    marginLeft: -8,
    marginRight: "auto",
    paddingLeft: 0,
    paddingRight: 16,

    "[data-action]": {
      padding: "0 4px",
      cursor: "pointer",
      ".svg-box": {
        width: 10,
        height: 10,
      },
      ">div": {
        position: "absolute",
        height: 2,
        width: 0,
        background: "#ffffff",
        left: 48,
        bottom: 6,
        animation: `${widthAnimation} 5s linear alternate`,
      },
    },
  },
}));

const widthAnimation = keyframes`from{width:calc(100% - 48px - 16px)}to{width:0}`;
