import SvgBox from "components/svg-box";
import { StyledUpload, StyledText } from "./styles";
import SingleUploader from "components/single-uploader";
import { Box, Typography } from "@mui/material";
import useUILogic from "./logic";
import ResultMetaModal from "./components/result-meta-modal";
import ViewCard from "../../components/view-card";
import { DEV_BACKEND_URL } from "routes/path";

export default function UploadResult({ view }) {
  const { openView, toggleView, onChange, uploadData, url } = useUILogic();

  return (
    <>
      <ViewCard title={view.title}>
        <div>
          {view?.data?.file_link && (
            <StyledText>
              <p>
                فایل نتایج ارزیابی بارگذاری شده است. می توانید با بارگذاری مجدد،
                بروز رسانی کنید.
              </p>
              <p>
                جهت مشاهده فایل قبلی{" "}
                <a
                  href={DEV_BACKEND_URL + view?.data?.file_link}
                  target="_blank"
                >
                  اینجا
                </a>{" "}
                کلیک کنید
              </p>
            </StyledText>
          )}

          <SingleUploader
            onChange={onChange}
            accept={{
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
          >
            {({ error }) => (
              <StyledUpload>
                <SvgBox src="/assets/icons/common/ic_upload.svg" />
                <div>بارگذاری فایل جدید</div>
                {error && (
                  <Typography color="error" variant="caption">
                    خطایی رخ داده است
                  </Typography>
                )}
              </StyledUpload>
            )}
          </SingleUploader>

          {view?.data?.template_file_link && (
            <Box
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                textDecoration: "underline",
                mt: "20px",
              }}
            >
              <a
                href={DEV_BACKEND_URL + view?.data?.template_file_link}
                target="_blank"
              >
                دانلود نمونه فایل
              </a>
            </Box>
          )}
        </div>
      </ViewCard>

      <ResultMetaModal
        open={openView}
        toggle={toggleView}
        uploadData={uploadData}
      />
    </>
  );
}
