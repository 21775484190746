import { Grid } from "@mui/material";
import CustomNumeralNumericFormat from "components/forms/custom-numeral-numeric-format";
import useUILogic from "./logic";
import { LoadingButton } from "@mui/lab";

export default function PriceEstimation() {
  const { setValue, isLoading, onConfirm } = useUILogic();

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Grid item xs={12} sm={6}>
        <CustomNumeralNumericFormat
          label="قیمت به تومان"
          sx={{ height: 46 }}
          onValueChange={({ value }) => setValue(value)}
        />
      </Grid>

      <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          sx={{ height: "100%", width: { xs: "100%", sm: "auto" } }}
          loading={isLoading}
          onClick={() => onConfirm()}
        >
          اعلام به پلتفرم
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
