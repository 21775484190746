import useAxios from "hooks/useAxios";
import useModal from "hooks/useModal";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const params = useParams();
  const navigate = useNavigate();

  // cancel
  const [openCancel, toggleCancel] = useModal();

  // success
  const [openSuccess, toggleSuccess] = useModal();

  // step
  const [step, setStep] = useState(1);
  const prevStep = () => {
    if (step === 1) {
      toggleCancel();
    } else {
      setStep((prev) => prev - 1);
    }
  };
  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  // get data
  const { isLoading: isLoadingGet, data: defaultData } = useAxios({
    url: Panel_API_Routes.auth.continue_registration,
    params: { q: params.q },
    loadOnStart: true,
  });

  // submit
  const [openConfirm, toggleConfirm] = useModal();
  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useAxios({
    url: Panel_API_Routes.auth.continue_registration,
    method: "put",
    params: { q: params.q },
  });

  const onSubmit = async (params) => {
    if (step === 5) {
      toggleConfirm(params);
      return;
    }

    // if params is null data haven't changed
    if (params) {
      try {
        const res = await mutateSubmit({ data: { params } });

        nextStep();
      } catch (error) {}
    } else {
      nextStep();
    }
  };

  const onConfirmLastStep = async () => {
    try {
      const res = await mutateSubmit({ data: { params: openConfirm } });

      toggleConfirm();
      toggleSuccess();
    } catch (error) {}
  };

  return {
    step,
    prevStep,
    //
    defaultData,
    isLoadingGet,
    //
    onSubmit,
    isLoadingSubmit,
    openConfirm,
    toggleConfirm,
    onConfirmLastStep,
    //
    navigate,
    openCancel,
    toggleCancel,
    //
    openSuccess,
    toggleSuccess,
  };
}
