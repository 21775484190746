import { styled } from "@mui/material/styles";

export const StyledWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: "#ffffff",
  borderRadius: 16,
  position: "relative",

  display: "flex",
  justifyContent: "center",
  padding: "146px 0 64px 0",

  ">div": {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    ">div:first-of-type": {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ">.svg-box": {
        width: 224,
        height: 224,
      },
      h1: {
        color: theme.palette.text.disabled,
        lineHeight: "38px",
        fontWeight: 400,
        fontSize: 32,
        marginTop: 12,
        span: {
          fontWeight: 500,
        },
        [theme.breakpoints.down("sm")]: {
          lineHeight: "22px",
          fontSize: 18,
        },
      },
      p: {
        color: theme.palette.text.disabled,
        lineHeight: "19px",
        fontWeight: 400,
        fontSize: 16,
        marginTop: 12,
        [theme.breakpoints.down("sm")]: {
          lineHeight: "17px",
          fontSize: 14,
        },
      },
    },

    ">div:last-of-type": {
      lineHeight: "22px",
      fontSize: 18,
      position: "relative",
      zIndex: 1,
      a: {
        fontWeight: 500,
        color: "inherit",
        textDecoration: "underline",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "17px",
        fontSize: 14,
      },
    },
  },

  ">svg[data-mountain]": {
    width: "100%",
    display: "block",
    position: "absolute",
    bottom: 0,
  },
}));
