import { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function Dropzone({ children, onError, onSuccess, ...other }) {
  const mutation = useAxios({
    method: "post",
    url: Panel_API_Routes.storage.upload,
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    try {
      const res = await mutation.mutate({ data: formData });

      const obj = {
        data: res?.data?.data,
      };
      onSuccess?.(obj);
    } catch (error) {
      onError?.(error);
    }
  }, []);

  const { getRootProps, getInputProps, ...dropzoneState } = useDropzone({
    onDrop,
    multiple: false,
    disabled: mutation.isLoading,
    ...other,
  });

  return (
    <div {...getRootProps()} style={{ borderRadius: "inherit" }}>
      <input {...getInputProps()} />
      {children({ dropzoneState, mutation })}
    </div>
  );
}

Dropzone.propTypes = {
  children: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};
