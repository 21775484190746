import { styled } from "@mui/material/styles";

export const StyledBox = styled("div")(({ theme }) => ({
  border: "1px solid #3A9C0C",
  padding: "12px 22px",
  backgroundColor: "#EFFAF0",
  borderRadius: 10,
  marginBottom: 34,

  ">div:first-of-type": {
    color: "#49892E",
    fontWeight: "bold",
  },
  ">div:last-of-type": {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    ">div": {
      flex: 1,
      ">span:first-of-type": {
        fontWeight: "bold",
      },
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      ">div": {
        "&:last-of-type": {
          marginTop: 8,
        },
      },
    },
  },
}));

export const StyledDatePicker = styled("div")(({ theme }) => ({
  width: "50%",
  marginBottom: 24,

  ".MuiFormLabel-root": {
    cursor: "pointer",
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
  ".MuiInputBase-root": {
    cursor: "pointer",

    input: {
      cursor: "pointer",
    },
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
