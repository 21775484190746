import { fDate1 } from "lib/formatTime";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";

export default function useUILogic({ defaultData, onSubmit }) {
  const methods = useForm({ defaultValues: defaultData });

  useEffect(() => {
    if (defaultData) {
      methods.reset({
        avatar: defaultData.avatar
          ? { data: { url: defaultData.avatar } }
          : null,
        full_name: defaultData.full_name || "",
        national_id: defaultData.national_id || "",
        birth_date: defaultData.birth_date
          ? new Date(defaultData.birth_date)
          : null,
      });
    }
  }, [defaultData]);

  const onNext = (params) => {
    if (Object.keys(methods.formState.dirtyFields).length) {
      onSubmit({
        ...params,
        avatar: params.avatar?.data?.url,
        birth_date: format(params.birth_date, "yyyy-MM-dd"),
      });
    } else {
      onSubmit(null);
    }
  };

  return {
    methods,
    onNext,
  };
}
