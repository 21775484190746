import RHKTextField from "components/forms/rhk-text-field";
import useUILogic from "./logic";
import { Button, Grid } from "@mui/material";
import RHKCustomPatternFormat from "components/forms/rhk-custom-pattern-format";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import RHKSelectProvince from "components/forms/rhk-select-province";
import RHKSelectCity from "components/forms/rhk-select-city";

export default function RegisterEvaluatorsForm2({
  prevStep,
  defaultData,
  onSubmit,
  isLoading,
}) {
  const { methods, onNext } = useUILogic({ defaultData, onSubmit });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onNext)}
        noValidate
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexDirection="column" rowSpacing={4}>
          <Grid item>
            <RHKSelectProvince
              name="province"
              label="استان *"
              rules={{ required: true }}
              disabled={isLoading}
              placeholder={"انتخاب کنید"}
            />
          </Grid>

          <Grid item>
            <RHKSelectCity
              name="city"
              label="شهر *"
              rules={{ required: true }}
              disabled={isLoading}
              placeholder={"انتخاب کنید"}
              province={methods.watch("province")?.value}
            />
          </Grid>

          <Grid item>
            <RHKCustomPatternFormat
              format="##### #####"
              name="postal_code"
              label="کد پستی *"
              placeholder="××××× ×××××"
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <RHKTextField
              name="address"
              label="آدرس *"
              placeholder=""
              multiline
              minRows={4}
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-around" mt={"auto"} pt={3}>
          <Grid item>
            <Button
              variant="contained"
              color="gray"
              onClick={prevStep}
              disabled={isLoading}
            >
              مرحله قبل
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              مرحله بعد
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
