import { useEffect, useMemo, useState } from "react";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import { useParams } from "react-router-dom";

export default function useUILogic() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState();

  const { mutate } = useAxios({
    url: Panel_API_Routes.erm.request(id),
  });

  const fetchRequest = async (showLoading = true) => {
    if (showLoading) setIsLoading(true);
    try {
      const res = await mutate();
      setRequestData(res.data?.data);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  const viewsData = useMemo(() => {
    const entries = requestData?.views?.map((view) => [view.tag, view]);
    return Object.fromEntries(entries || []);
  }, [requestData]);

  return {
    requestData,
    setRequestData,
    isLoading,
    viewsData,
  };
}
