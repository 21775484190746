import SvgBox from "components/svg-box";
import { Box, Typography } from "@mui/material";
import ViewCard from "../../components/view-card";
import { DEV_BACKEND_URL } from "routes/path";

export default function InspectUploadedResult({ view }) {
  return (
    <ViewCard title={view.title}>
      <Typography mt="20px">
        جهت مشاهده آخرین فایل نتیجه ارزیابی بارگذاری شده توسط سر ارزیاب، بر روی
        لینک زیر کلیک کنید
      </Typography>

      <Box
        component={"a"}
        href={DEV_BACKEND_URL + view?.data?.file_link}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: "flex",
          alignItems: "center",
          textDecoration: "underline",
          justifyContent: "center",
          mt: "32px",
        }}
      >
        <SvgBox
          src="/assets/icons/common/ic_download.svg"
          sx={{ width: "35px", height: "35px", ml: "14px" }}
        />
        <span>دانلود و مشاهده نتیجه ارزیابی</span>
      </Box>
    </ViewCard>
  );
}
