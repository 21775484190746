import { styled } from "@mui/material/styles";

export const StyledModalTable = styled("div")(({ theme }) => ({
  "[data-table-head]": {
    display: "none",
    strong: {
      fontSize: 14,
      fontWeight: 400,
      borderBottom: 0,
    },
  },

  "[data-table-row]": {
    ">div": {
      borderTop: 0,
      padding: "8px 8px",
      fontSize: 14,
    },
  },
}));
