import useModal from "hooks/useModal";
import { useRequestContext } from "../../request-context";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function useUILogic({ action }) {
  const { setRequestData } = useRequestContext();
  const [open, toggle] = useModal();
  const { id } = useParams();
  const [textField, setTextField] = useState("");

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.erm.request(id),
    method: "put",
  });

  const onConfirm = async () => {
    const data = {
      ...action,
      required_params: undefined,
      description: textField,
    };

    try {
      const res = await mutate({ data });
      setRequestData(res.data?.data);

      toggle();
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
      setTextField("");
    } catch (error) {}
  };

  return {
    open,
    toggle,
    onConfirm,
    isLoading,
    textField,
    setTextField,
  };
}
