import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

export default function useUILogic({ defaultData, onSubmit }) {
  const methods = useForm();

  const lastEducationalCertOptions = useMemo(
    () => [
      {
        value: "high school diploma",
        label: "دیپلم",
      },
      {
        value: "associate degree",
        label: "کاردانی",
      },
      {
        value: "bachelor",
        label: "لیسانس",
      },
      {
        value: "master",
        label: "کارشناسی ارشد",
      },
      {
        value: "phd",
        label: "دکترا",
      },
    ],
    []
  );

  useEffect(() => {
    if (defaultData) {
      const last_educational_cert =
        lastEducationalCertOptions.find(
          ({ value }) => value === defaultData.last_educational_cert
        ) || null;

      methods.reset({
        last_educational_cert,
        field_of_study: defaultData.field_of_study || "",
      });
    }
  }, [defaultData]);

  const onNext = (params) => {
    if (Object.keys(methods.formState.dirtyFields).length) {
      onSubmit({
        ...params,
        last_educational_cert: params.last_educational_cert?.value,
      });
    } else {
      onSubmit(null);
    }
  };

  return {
    methods,
    onNext,
    lastEducationalCertOptions,
  };
}
