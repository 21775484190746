import { styled } from "@mui/material/styles";

export const StyledAuth = styled("div")(({ theme, isFormPage }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ">div": {
    minWidth: isFormPage ? 506 : 631,
    maxWidth: isFormPage ? 506 : 631,
    boxShadow: "0px 4px 6px #00000029",
    borderRadius: 16,
    backgroundColor: "#ffffff",
    // padding: "42px 80px 21px",
    textAlign: "center",
    minHeight: isFormPage ? "unset" : "70vh",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      maxWidth: "unset",
      minHeight: "inherit",
      // padding: "54px 11px",
      width: "100%",
      borderRadius: 0,
    },

    ">div:last-of-type": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingLeft: isFormPage ? "80px" : "26px",
      paddingRight: isFormPage ? "80px" : "26px",

      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
  },
}));
