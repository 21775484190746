import { useMemo } from "react";
import ActionButton from "../../components/action-button";
import { Box } from "@mui/material";

export default function useUILogic() {
  const columns = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "عنوان",
        render: (item) => item.title,
      },
      {
        title: "عملیات",
        render: (item) => <ActionButton action={item.action} />,
      },
    ],
    []
  );

  return {
    columns,
  };
}
