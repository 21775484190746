import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  darken,
} from "@mui/material";
import useUILogic from "./logic";
import ConfirmModal from "components/modal/common/confirm-modal";
import Modal from "components/modal";
import { LoadingButton } from "@mui/lab";
import SvgBox from "components/svg-box";

export default function ActionButton({ action, customMessage }) {
  const { open, toggle, onConfirm, isLoading, textField, setTextField } =
    useUILogic({ action });

  return (
    <>
      {action?.title?.startsWith("ic_") ? (
        <IconButton onClick={() => toggle()}>
          <SvgBox src={`/assets/icons/common/${action?.title}.svg`} />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          sx={{
            width: "100%",
            minWidth: "unset",
            ...(action?.color && {
              backgroundColor: action?.color,
              "&:hover": {
                backgroundColor: darken(action?.color, 0.3),
              },
            }),
          }}
          onClick={() => toggle()}
        >
          {action?.title}
        </Button>
      )}

      {/* description modal */}
      {action?.required_params?.indexOf("description") > -1 ? (
        <Modal open={open} toggle={toggle}>
          <Typography
            color={"primary"}
            fontSize={"20px"}
            fontWeight={"bold"}
            textAlign={"center"}
            mb="32px"
          >
            {action?.title}
          </Typography>

          <TextField
            value={textField}
            onChange={(e) => setTextField(e.target.value)}
            multiline
            minRows={5}
            placeholder="در صورت نیاز توضیحات را وارد کنید"
            sx={{ mb: "15px" }}
          />

          <Grid
            container
            spacing={2}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={isLoading}
                onClick={() => onConfirm()}
              >
                ارسال
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="contained" color="gray" onClick={() => toggle()}>
                بستن
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        // confirm modal
        <ConfirmModal
          open={open}
          toggle={toggle}
          onConfirm={onConfirm}
          isLoading={isLoading}
        >
          {customMessage || `آیا از ${action?.title} مطمئنید؟`}
        </ConfirmModal>
      )}
    </>
  );
}
