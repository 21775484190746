import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { useNavigate, useParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { enqueueSnackbar } from "notistack";

export default function useUILogic() {
  const { id } = useParams();
  const navigate = useNavigate();

  // get data
  const {
    data,
    isLoading: isLoadingGet,
    error,
  } = useAxios({
    url: Panel_API_Routes.auth.registrants,
    params: { id },
    loadOnStart: true,
  });

  // action
  const [openConfirm, toggleConfirm] = useModal();

  const { mutate, isLoading: isLoadingConfirm } = useAxios({
    url: Panel_API_Routes.auth.registrants,
    method: "put",
    params: { id },
  });

  const onConfirm = async () => {
    try {
      const res = await mutate({ data: { params: { status: openConfirm } } });
      toggleConfirm();
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
      navigate(Panel_Routes.dashboard.index);
    } catch (error) {}
  };

  return {
    data,
    isLoadingGet,
    error,
    //
    onConfirm,
    isLoadingConfirm,
    openConfirm,
    toggleConfirm,
  };
}
