import { useRequestContext } from "../../request-context";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function useUILogic() {
  const { setRequestData } = useRequestContext();
  const { id } = useParams();
  const [value, setValue] = useState();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.erm.requests,
    method: "put",
    params: { id },
  });

  const onConfirm = async () => {
    if (!value) return;

    try {
      const data = {
        params: {
          action: { tag: "ir_action_estimate_price", value },
        },
      };

      const res = await mutate({ data });
      setRequestData(res.data?.data);
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
    } catch (error) {}
  };

  return {
    setValue,
    isLoading,
    onConfirm,
  };
}
