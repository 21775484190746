import RHKTextField from "components/forms/rhk-text-field";
import useUILogic from "./logic";
import { Button, Grid } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import RHKSelect from "components/forms/rhk-select";

export default function RegisterEvaluatorsForm3({
  prevStep,
  defaultData,
  onSubmit,
  isLoading,
}) {
  const { methods, onNext, lastEducationalCertOptions } = useUILogic({
    defaultData,
    onSubmit,
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onNext)}
        noValidate
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexDirection="column" rowSpacing={4}>
          <Grid item>
            <RHKSelect
              name="last_educational_cert"
              label="آخرین مدرک تحصیلی  *"
              rules={{ required: true }}
              disabled={isLoading}
              options={lastEducationalCertOptions}
              placeholder={"انتخاب کنید"}
            />
          </Grid>

          <Grid item>
            <RHKTextField
              name="field_of_study"
              label="رشته تحصیلی *"
              placeholder=""
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-around" mt={"auto"} pt={3}>
          <Grid item>
            <Button
              variant="contained"
              color="gray"
              onClick={prevStep}
              disabled={isLoading}
            >
              مرحله قبل
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              مرحله بعد
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
