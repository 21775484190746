import { styled } from "@mui/material/styles";

export const StyledText = styled("div")(({ theme }) => ({
  lineHeight: "30px",
  a: {
    textDecoration: "underline",
    fontWeight: 700,
  },
}));

export const StyledUpload = styled("div")(({ theme }) => ({
  marginTop: 24,
  minHeight: 153,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  color: "#2A3875",
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231B1B1B' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  backgroundColor: "#E4E8FB",
  ">.svg-box": {
    width: 69,
    height: 69,
  },
  ">div": {
    margin: "8px 0",
  },
}));
