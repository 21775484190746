import Modal from "components/modal";
import useUILogic from "./logic";
import Table from "components/table";
import { Box, Button, Typography } from "@mui/material";

export default function AddEvaluatorModal({ isListEmpty }) {
  const {
    open,
    toggle,
    //
    data,
    isLoadingGet,
    //
    columns,
  } = useUILogic();

  return (
    <>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button variant="contained" onClick={() => toggle()}>
          {isListEmpty ? "انتخاب" : "اضافه کردن"} ارزیاب
        </Button>
      </Box>

      <Modal open={open} toggle={toggle} maxWidth="md">
        <Typography
          fontSize={"20px"}
          fontWeight={"bold"}
          textAlign={"center"}
          mb="40px"
          color="primary"
        >
          لیست ارزیابان
        </Typography>

        <Table
          columns={columns}
          data={data}
          gridTemplateColumns="56px 1fr 120px 120px 1fr"
          isLoading={isLoadingGet}
          rowKey="full_name"
        />

        <Button
          variant="contained"
          color="gray"
          sx={{ mt: "60px" }}
          onClick={() => toggle()}
        >
          بستن
        </Button>
      </Modal>
    </>
  );
}
