import { styled } from "@mui/material/styles";

export const StyledContent = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0px 4px 4px 0px #00000040",
  borderRadius: 10,

  [theme.breakpoints.down("md")]: {
    padding: "16px 16px",
  },
}));
