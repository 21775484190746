import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Spin from "components/spin";

export default function PageWrapper({
  pageTitle,
  title,
  isLoading,
  LoadingComponent,
  error,
  children,
}) {
  if (error) {
    if (error.response?.status === 401) return <Navigate to="/401" />;
    else return <Navigate to="/500" />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxWidth: "850px",
          margin: "0 auto",
        }}
      >
        <Typography variant="h1" mb={6} color="primary">
          {title}
        </Typography>

        {isLoading && LoadingComponent ? (
          <LoadingComponent />
        ) : (
          <Spin sx={{ height: "100%" }} isLoading={!!isLoading}>
            {children}
          </Spin>
        )}
      </Box>
    </>
  );
}

PageWrapper.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node,
};
