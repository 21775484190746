import useModal from "hooks/useModal";
import { useState } from "react";

export default function useUILogic() {
  const [openView, toggleView] = useModal();
  const [uploadData, setUploadData] = useState();

  const onChange = (o) => {
    if (o) {
      toggleView();
      setUploadData(o.data);
    }
  };

  return {
    openView,
    toggleView,
    onChange,
    uploadData,
  };
}
