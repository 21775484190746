export const DEV_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const DEV_BACKEND_URL_API = `${DEV_BACKEND_URL}/api/method`;

export const Panel_Routes = Object.freeze({
  auth: {
    otp: "/auth/otp",
    login: {
      index: "/auth/login",
      token: (q) => `/auth/login/${q}`,
    },
    register: {
      evaluators: "/auth/register/evaluators",
      evaluatorsForm: (q) => `/auth/register/evaluators/${q}`,
    },
  },
  dashboard: {
    index: "/dashboard",
    registrants: (q) => `/dashboard/registrants/${q}`,
    evaluation: (q) => `/dashboard/evaluation/${q}`,
  },
});

export const Panel_API_Routes = Object.freeze({
  auth: {
    login: "v2/auth/login",
    logout: "v2/auth/logout",
    otp_verify: "v2/auth/otp/verify",
    otp_resend: "v2/auth/otp/resend",
    register: "iau_engineering_network.api.v1.auth.register",
    continue_registration:
      "iau_engineering_network.api.v1.auth.continue_registration",
    registrants: "iau_engineering_network.api.v1.auth.registrants",
  },
  user: {
    index: "v2/user",
  },
  erm: {
    requests: "v2/erm/requests",
    request: (q) => `v2/erm/requests/${q}`,
  },
  irm: {
    requests: "v2/irm/requests",
  },
  resources: {
    evaluators: "v2/resources/evaluators",
    evaluation_fields:
      "iau_engineering_network.api.v1.resources.evaluation_fields",
    provinces: "iau_engineering_network.api.v1.resources.provinces",
    cities: "iau_engineering_network.api.v1.resources.cities",
  },
  storage: {
    upload: "/v2/storage/upload",
  },
});
