import { Box, Button, Grid } from "@mui/material";
import PageWrapper from "components/page-wrapper";
import { REVIEW_EVALUATION_PAGE_TITLE } from "lib/page-titles";
import { Link } from "react-router-dom";
import useUILogic from "./logic";
import RequestContextProvider from "./request-context";
import ViewCard from "./components/view-card";

import TopSection from "./components/top-section";
import PriceEstimation from "./components/price-estimation";
import EvaluatorsUploads from "./components/evaluators-uploads";

// new
import History from "./view-components/history";
import ActionButton from "./components/action-button";
import AssignFieldManager from "./view-components/assign-field-manager";
import EvaluatorsManager from "./view-components/evaluators-manager";
import PriceEstimator from "./view-components/price-estimator";
import AssignHeadEvaluator from "./view-components/assign-head-evaluator";
import UploadResult from "./view-components/upload-result";
import DateCoordinator from "./view-components/date-coordinator";
import InspectUploadedResult from "./view-components/inspect-uploaded-result";

export default function EvaluationPage() {
  const { requestData, setRequestData, isLoading, viewsData } = useUILogic();

  return (
    <PageWrapper
      pageTitle={REVIEW_EVALUATION_PAGE_TITLE}
      title="جزئیات درخواست"
      isLoading={isLoading}
    >
      <RequestContextProvider
        value={{
          requestData,
          setRequestData,
        }}
      >
        <Grid container spacing={{ xs: 1, md: 3 }}>
          <TopSection />

          <Grid item xs={12}>
            <ViewCard title="تاریخچه">
              <History />
            </ViewCard>
          </Grid>

          {!!requestData?.views?.find(
            ({ tag }) => tag === "ir_detail_view_price_estimation"
          ) && (
            <Grid item xs={12}>
              <ViewCard title="برآورد هزینه">
                <PriceEstimation />
              </ViewCard>
            </Grid>
          )}

          {!!requestData?.views?.find(
            ({ tag }) => tag === "ir_detail_view_evaluators_uploads"
          ) && (
            <Grid item xs={12}>
              <ViewCard title="نتایج ارزیابی">
                <EvaluatorsUploads />
              </ViewCard>
            </Grid>
          )}

          {/* new */}

          {viewsData?.er_view_assign_field_manager && (
            <Grid item xs={12}>
              <AssignFieldManager
                view={viewsData?.er_view_assign_field_manager}
              />
            </Grid>
          )}

          {viewsData?.er_view_evaluators_manager && (
            <Grid item xs={12}>
              <EvaluatorsManager view={viewsData?.er_view_evaluators_manager} />
            </Grid>
          )}

          {viewsData?.er_view_price_estimator && (
            <Grid item xs={12}>
              <PriceEstimator view={viewsData?.er_view_price_estimator} />
            </Grid>
          )}

          {viewsData?.er_view_assign_head_evaluator && (
            <Grid item xs={12}>
              <AssignHeadEvaluator
                view={viewsData?.er_view_assign_head_evaluator}
              />
            </Grid>
          )}

          {viewsData?.er_view_upload_result && (
            <Grid item xs={12}>
              <UploadResult view={viewsData.er_view_upload_result} />
            </Grid>
          )}

          {viewsData?.er_view_date_coordinator && (
            <Grid item xs={12}>
              <DateCoordinator view={viewsData.er_view_date_coordinator} />
            </Grid>
          )}

          {viewsData?.er_view_inspect_uploaded_result && (
            <Grid item xs={12}>
              <InspectUploadedResult
                view={viewsData.er_view_inspect_uploaded_result}
              />
            </Grid>
          )}

          {!!requestData?.actions?.length && (
            <Grid item xs={12}>
              <ViewCard title="عملیات">
                <Grid
                  container
                  spacing={2}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  {requestData?.actions?.map((action) => (
                    <Grid item key={action?.tag} xs={12} sm={6.1} md={4}>
                      <ActionButton action={action} />
                    </Grid>
                  ))}
                </Grid>
              </ViewCard>
            </Grid>
          )}

          <Grid item xs={4} />
          <Grid item xs={4}>
            <Button
              component={Link}
              to="/"
              variant="contained"
              color="gray2"
              sx={{ width: "100%", mt: 1 }}
            >
              بازگشت به کارتابل
            </Button>
          </Grid>
        </Grid>
      </RequestContextProvider>
    </PageWrapper>
  );
}
