import { createContext, useContext } from "react";

const RequestContext = createContext({
  requestData: null,
  setRequestData: () => {},
});

export default function RequestContextProvider({ children, value }) {
  return (
    <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
  );
}

export function useRequestContext() {
  const context = useContext(RequestContext);

  if (!context) {
    throw new Error("useRequestContext must be used within RequestContext");
  }

  return context;
}
