export default function MuiChip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#242424",
          padding: "0 4px",
          "&.MuiChip-filledSuccess": {
            backgroundColor: "#76C875",
          },
          "&.MuiChip-filledError": {
            backgroundColor: "#FF9090",
          },
        },
      },
    },
  };
}
