import { useMemo } from "react";
import { useRequestContext } from "../../request-context";
import { Typography } from "@mui/material";
import useModal from "hooks/useModal";

export default function useUILogic() {
  const { requestData } = useRequestContext();

  const [openInfoModal, toggleInfoModal] = useModal();

  const columns = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "وضعیت",
        render: (item) => item.description,
      },
      {
        title: "زمان",
        sx: { justifyContent: "center" },
        render: (item) => item.timestamp ?? "--",
      },
      {
        title: "عملیات",
        sx: { justifyContent: "center" },
        render: (item) =>
          item.meta_data ? (
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#1E83BB",
              }}
              onClick={() => toggleInfoModal(item.meta_data)}
            >
              مشاهده اطلاعات
            </Typography>
          ) : (
            ""
          ),
      },
    ],
    []
  );

  return {
    columns,
    states_log: requestData?.states_log,
    openInfoModal,
    toggleInfoModal,
  };
}
