import useAxios from "hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Panel_API_Routes } from "routes/path";
import { STORAGE_KEY_USER } from "../../../../config";
import { enqueueSnackbar } from "notistack";

export default function useUILogic() {
  const params = useParams();
  const navigate = useNavigate();

  const { error } = useAxios({
    url: Panel_API_Routes.auth.login,
    params: { token: params.token },
    loadOnStart: true,
    onSuccess: (res) => {
      localStorage.setItem(
        STORAGE_KEY_USER,
        JSON.stringify(res.data.data.user)
      );

      enqueueSnackbar("ورود موفقیت آمیز بود", { variant: "success" });
      navigate("/");
    },
  });

  return {
    error,
  };
}
