import { Navigate, useRoutes } from "react-router-dom";
import { Panel_Routes } from "./path";

import AuthGuard from "guards/auth-guard";

// layouts
import DashboardLayout from "layouts/dashboard";
import AuthLayout from "layouts/auth";

// error
import ErrorPage from "pages/error/errorPage";
import Page404 from "pages/error/page404";
import Page401 from "pages/error/page401";
import Page500 from "pages/error/page500";

// auth
import AuthOtpPage from "pages/auth/auth-otp";
import LoginPage from "pages/auth/login/login";
import LoginTokenPage from "pages/auth/login/login-token";
import RegisterEvaluatorsPage from "pages/auth/register/register-evaluators";
import RegisterEvaluatorsFormPage from "pages/auth/register/register-evaluators-form";

import DashboardPage from "pages/panel/dashboard";
import RegistrantsPage from "pages/panel/registrants";
import EvaluationPage from "pages/panel/evaluation";

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={Panel_Routes.dashboard.index} replace />,
        },
        {
          path: Panel_Routes.dashboard.index,
          element: <DashboardPage />,
        },
        {
          path: Panel_Routes.dashboard.registrants(":id"),
          element: <RegistrantsPage />,
        },
        {
          path: Panel_Routes.dashboard.evaluation(":id"),
          element: <EvaluationPage />,
        },

        { path: "401", element: <Page401 /> },
        { path: "500", element: <Page500 /> },
      ],
    },

    {
      element: <AuthLayout />,
      children: [
        {
          path: Panel_Routes.auth.otp,
          element: <AuthOtpPage />,
        },
        {
          path: Panel_Routes.auth.login.index,
          element: <LoginPage />,
        },
        {
          path: Panel_Routes.auth.login.token(":token"),
          element: <LoginTokenPage />,
        },
        {
          path: Panel_Routes.auth.register.evaluators,
          element: <RegisterEvaluatorsPage />,
        },
        {
          path: Panel_Routes.auth.register.evaluatorsForm(":q"),
          element: <RegisterEvaluatorsFormPage />,
        },
      ],
    },

    { path: "error", element: <ErrorPage /> },
    { path: "*", element: <Page404 /> },
  ]);

  return routes;
}
