import {
  TOOLBAR_HEIGHT,
  TOOLBAR_HEIGHT_MOBILE,
  NAVBAR_WIDTH,
  NAVBAR_WIDTH_MOBILE,
} from "../config";

const mixins = {
  toolbar: {
    height: TOOLBAR_HEIGHT,
    height_mobile: TOOLBAR_HEIGHT_MOBILE,
  },
  navbar: {
    width: NAVBAR_WIDTH,
    width_mobile: NAVBAR_WIDTH_MOBILE,
  },
};

export default mixins;
