const typography = {
  fontFamily: "'Noto Sans Arabic', sans-serif",
  h1: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "24px",
  },
  h2: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "24px",
  },
};

export default typography;
