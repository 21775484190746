export default function MuiButton(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "none",
          padding: "6px 24px",
          // minWidth: 233,
          minWidth: 129,
        },
      },
    },
  };
}
