import { TextField } from "@mui/material";
import { en2pr, pr2en } from "lib/helpers";
import { useController, useFormContext } from "react-hook-form";
import { NumberFormatBase, usePatternFormat } from "react-number-format";

export default function RHKCustomPatternFormat({
  name,
  rules,
  onChange,
  format: formatString,
  ...rest
}) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  const { format, removeFormatting } = usePatternFormat({
    format: formatString,
  });

  const _format = (val) => {
    const _val = format(val);
    return en2pr(_val.trim());
  };

  const _removeFormatting = (val) => {
    const _val = pr2en(val);
    return removeFormatting(_val);
  };

  return (
    <NumberFormatBase
      format={_format}
      removeFormatting={_removeFormatting}
      customInput={TextField}
      inputRef={ref}
      value={field.value}
      onValueChange={(e) => {
        field.onChange(e.value);
        onChange?.(e);
      }}
      error={!!error}
      helperText={error ? error?.message || "این مقدار الزامی است" : ""}
      {...rest}
    />
  );
}
