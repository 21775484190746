import { styled, keyframes } from "@mui/material/styles";

export const StyledBlinkingBadge = styled("span")(({ theme, size }) => ({
  width: size,
  height: size,
  borderRadius: "100%",
  backgroundColor: "#647ED1",
  animation: `${blink} 1s infinite linear alternate`,
}));

const blink = keyframes`from{opacity:0.2}to{opacity:1}`;
