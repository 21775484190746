import { styled } from "@mui/material/styles";

export const StyledContent = styled("div")(({ theme }) => ({
  height: "40vh",
  overflow: "auto",
  position: "relative",
  direction: "ltr",

  "&::-webkit-scrollbar": {
    width: 8,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
  },
  "&::-webkit-scrollbar-track-piece:start": {
    marginTop: 50,
  },

  "[data-table-root]": {
    overflow: "unset",
    direction: "rtl",

    "[data-table-head]": {
      strong: {
        height: 50,
        position: "sticky",
        top: 0,
        backgroundColor: "white",
      },
    },
  },
}));
