import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

export default function RHKSelect({
  name,
  rules,
  label,
  placeholder,
  enableSearch = false,
  onChange,
  startAdornment,
  ...rest
}) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  return (
    <Autocomplete
      {...field}
      onChange={(e, newValue) => {
        field.onChange(newValue);
        onChange?.(e);
      }}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
            readOnly: !enableSearch,
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            startAdornment,
          }}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error ? error?.message || "این مقدار الزامی است" : ""}
        />
      )}
      {...rest}
    />
  );
}

RHKSelect.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  enableSearch: PropTypes.bool,
  defaultValue: PropTypes.object,
};
