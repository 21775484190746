import { useMemo } from "react";
import ActionButton from "../../components/action-button";
import { Box } from "@mui/material";

export default function useUILogic() {
  const columns = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "توضیحات",
        render: (item) => item.label,
      },
      {
        title: "مبلغ",
        render: (item) => item.value,
      },
    ],
    []
  );

  return {
    columns,
  };
}
