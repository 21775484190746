import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "hooks/useInterval";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";

export default function useUILogic() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const methods = useForm();

  // count down
  const [countdown, setCountdown] = useState(120);
  useInterval(
    () => {
      setCountdown(countdown - 1);
    },
    countdown > 0 ? 1000 : null
  );

  // verfiy
  const { isLoading: isLoadingVerify, mutate: mutateVerify } = useAxios({
    url: Panel_API_Routes.auth.otp_verify,
    method: "post",
  });

  const onSubmit = async (data) => {
    try {
      const res = await mutateVerify({
        data: {
          otp_id: searchParams.get("otp_id"),
          otp: data.otp,
        },
      });

      navigate(res.data?.data?.redirect);
    } catch (error) {}
  };

  // resend otp
  const { isLoading: isLoadingResend, mutate: mutateResend } = useAxios({
    url: Panel_API_Routes.auth.otp_resend,
    method: "post",
  });

  const onResend = async () => {
    if (countdown > 0 || isLoadingResend) return;

    try {
      const res = await mutateResend({
        data: {
          otp_id: searchParams.get("otp_id"),
        },
      });
      enqueueSnackbar("کد دوباره ارسال شد", { variant: "success" });
      setCountdown(120);
    } catch (error) {}
  };

  return {
    mode: searchParams.get("mode"),
    //
    countdown,
    setCountdown,
    //
    methods,
    onSubmit,
    isLoadingVerify,
    //
    onResend,
    isLoadingResend,
  };
}
