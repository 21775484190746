import { useMemo } from "react";
import ActionButton from "../../components/action-button";
import SvgBox from "components/svg-box";

export default function useUILogic() {
  const columns = useMemo(
    () => [
      {
        title: "#",
        render: (item, { row }) => row,
      },
      {
        title: "نام",
        render: (item) => item.full_name,
      },
      {
        title: "استاد عرصه",
        sx: { justifyContent: "center", ".svg-box": { color: "#438006" } },
        render: (item) =>
          item.is_master_of_field && (
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 33, height: 33 }}
            />
          ),
      },
      {
        title: "نوع",
        render: (item) => item.type,
      },
      {
        title: "عملیات",
        render: (item) => <ActionButton action={item.action} />,
      },
    ],
    []
  );

  return {
    columns,
  };
}
