import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { STORAGE_KEY_USER } from "../config";

export const useGlobalStore = create(
  devtools((set, get) => ({
    user: null,
    sideMenu: null,
    notifications: null,

    clearGlobalStore: () => {
      set({
        user: null,
        sideMenu: null,
        notifications: null,
      });
    },
  }))
);
