import { useState } from "react";
import { useRequestContext } from "../../request-context";
import { useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";

export default function useUILogic({ view }) {
  const [open, setOpen] = useState(false);
  const [dateTime, setDateTime] = useState(null);

  const { setRequestData } = useRequestContext();
  const { id } = useParams();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.erm.request(id),
    method: "put",
  });

  const onConfirm = async () => {
    const data = {
      ...view.data?.action,
      required_params: undefined,
      date_time: format(dateTime, "yyyy-MM-dd HH:mm:ss"),
    };

    try {
      const res = await mutate({ data });
      setRequestData(res.data?.data);
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
    } catch (error) {}
  };

  return {
    open,
    setOpen,
    dateTime,
    setDateTime,
    isLoading,
    onConfirm,
  };
}
