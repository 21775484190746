import PageWrapper from "components/page-wrapper";
import { REVIEW_EVALUATOR_PAGE_TITLE } from "lib/page-titles";
import useUILogic from "./logic";
import { StyledContent, StyledLoading, StyledNotFound } from "./styles";
import { Button, Chip, CircularProgress, Grid } from "@mui/material";
import Spin from "components/spin";
import Image from "components/image";
import { DEV_BACKEND_URL, Panel_Routes } from "routes/path";
import { Link } from "react-router-dom";
import ConfirmModal from "components/modal/common/confirm-modal";

export default function RegistrantsPage() {
  const {
    data,
    isLoadingGet,
    error,
    //
    onConfirm,
    isLoadingConfirm,
    openConfirm,
    toggleConfirm,
  } = useUILogic();

  return (
    <>
      <PageWrapper
        pageTitle={REVIEW_EVALUATOR_PAGE_TITLE}
        title="بررسی ثبت نام ارزیاب"
      >
        {isLoadingGet ? (
          <StyledLoading>
            <CircularProgress size={70} />
          </StyledLoading>
        ) : error ? (
          <StyledNotFound>
            <div>درخواست ثبت نامی یافت نشد</div>
            <Button
              component={Link}
              to={Panel_Routes.dashboard.index}
              variant="contained"
              color="gray2"
            >
              بازگشت به کارتابل
            </Button>
          </StyledNotFound>
        ) : (
          <StyledContent>
            <div data-profile-image>
              <Image src={DEV_BACKEND_URL + data?.avatar} ratio="1/1" />
            </div>

            <Grid container columnSpacing={{ sm: "20px", md: "60px" }}>
              <Grid item xs={12} sm={6}>
                <div data-column>
                  <div>نام و نام خانوادگی : </div>
                  <span>{data?.full_name || "_"}</span>
                </div>

                <div>
                  <div>وضعیت :</div>
                  <span>
                    <Chip
                      color={data?.is_complete ? "success" : "error"}
                      label={
                        data?.is_complete ? "تکمیل ثبت نام" : "ثبت نام ناقص"
                      }
                    />
                  </span>
                </div>

                <div>
                  <div>کد ملی :</div>
                  <span>{data?.national_id || "_"}</span>
                </div>

                <div>
                  <div>تاریخ تولد :</div>
                  <span>{data?.birth_date || "_"}</span>
                </div>

                <div>
                  <div>استان :</div>
                  <span>{data?.province || "_"}</span>
                </div>

                <div>
                  <div>شهر :</div>
                  <span>{data?.city || "_"}</span>
                </div>

                <div>
                  <div>کدپستی :</div>
                  <span>{data?.postal_code || "_"}</span>
                </div>

                <div data-column>
                  <div>آدرس :</div>
                  <span>{data?.address || "_"}</span>
                </div>

                <div>
                  <div>آخرین مدرک تحصیلی :</div>
                  <span>{data?.last_educational_cert || "_"}</span>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div data-column>
                  <div>رشته تحصیلی :</div>
                  <span>{data?.field_of_study || "_"}</span>
                </div>

                <div data-column>
                  <div>شغل :</div>
                  <span>{data?.job_title || "_"}</span>
                </div>

                <div>
                  <div>عضویت در دانشگاه آزاد :</div>
                  <span>{data?.is_member_of_azad ? "بله" : "خیر" || "_"}</span>
                </div>

                <div>
                  <div>سمت در دانشگاه آزاد :</div>
                  <span>{data?.position_at_azad || "_"}</span>
                </div>

                <div data-column>
                  <div>نام واحد دانشگاه :</div>
                  <span>{data?.azad_unit_name || "_"}</span>
                </div>

                <div data-column>
                  <div>حوزه ارزیابی :</div>
                  <span>{data?.evaluation_field || "_"}</span>
                </div>

                <div>
                  <div>تصویر کارت ملی :</div>
                  <span>
                    {data?.id_card_file ? (
                      <a
                        href={DEV_BACKEND_URL + data?.id_card_file}
                        target="_blank"
                      >
                        مشاهده
                      </a>
                    ) : (
                      "_"
                    )}
                  </span>
                </div>

                <div>
                  <div>فایل رزومه :</div>
                  <span>
                    {data?.resume_file ? (
                      <a
                        href={DEV_BACKEND_URL + data?.resume_file}
                        target="_blank"
                      >
                        مشاهده
                      </a>
                    ) : (
                      "_"
                    )}
                  </span>
                </div>

                <div>
                  <div>مدارک مرتبط با تخصص :</div>
                  <span>
                    {data?.other_document_file ? (
                      <a
                        href={DEV_BACKEND_URL + data?.other_document_file}
                        target="_blank"
                      >
                        مشاهده
                      </a>
                    ) : (
                      "_"
                    )}
                  </span>
                </div>
              </Grid>
            </Grid>

            <div>
              <Button
                variant="contained"
                onClick={() => toggleConfirm("approved")}
              >
                تایید ثبت نام
              </Button>
              <Button
                variant="contained"
                color="gray"
                onClick={() => toggleConfirm("rejected")}
              >
                عدم تایید
              </Button>
            </div>
          </StyledContent>
        )}
      </PageWrapper>

      <ConfirmModal
        open={!!openConfirm}
        toggle={toggleConfirm}
        onConfirm={onConfirm}
        isLoading={isLoadingConfirm}
      >
        آیا از {openConfirm === "approved" ? "تایید ثبت نام" : "عدم تایید"}{" "}
        مطمئنید؟
      </ConfirmModal>
    </>
  );
}
