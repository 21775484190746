import { format } from "date-fns-jalali";

export const en2pr = (s) => {
  if (!s && s !== 0) return "";
  return String(s).replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
};

export const pr2en = (s) => {
  if (!s) return "";
  return String(s).replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
};

export const fDate = (ds) => {
  if (!ds) return "";
  return en2pr(format(new Date(ds), "yyyy/MM/dd"));
};
