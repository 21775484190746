import { useEffect, useState } from "react";
import useResponsive from "hooks/useResponsive";
import { useGlobalStore } from "store/globalStore";
import { useLocation } from "react-router-dom";
import useLogout from "hooks/useLogout";
import useModal from "hooks/useModal";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic() {
  // data
  const sideMenu = useGlobalStore((state) => state.sideMenu);
  const user = useGlobalStore((state) => state.user);

  // responsive
  const isDesktop = useResponsive("up", "lg");
  const [wide, setWide] = useState(isDesktop);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isDesktop) setWide(true);
    else setWide(false);
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop) setWide(false);
    // eslint-disable-next-line
  }, [pathname]);

  // logout
  const { logout } = useLogout();
  const [openLogout, toggleLogout] = useModal();

  // role
  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.user.index,
    method: "put",
  });

  const onChangeRole = async (e, newValue) => {
    try {
      await mutate({ data: { active_role: newValue?.value } });
      window.location = "/";
    } catch (error) {}
  };

  return {
    sideMenu,
    user,
    //
    wide,
    setWide,
    isDesktop,
    //
    openLogout,
    toggleLogout,
    logout,
    //
    onChangeRole,
    isLoading,
  };
}
