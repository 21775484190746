import { styled } from "@mui/material/styles";

export const StyledTable = styled("div")(({ theme }) => ({
  display: "grid",
  overflow: "auto",

  "[data-table-head]": {
    display: "contents",

    ">strong": {
      display: "flex",
      alignItems: "center",
      padding: "12px 8px",
      borderBottom: "1px solid #D7D7D7",
    },
  },

  "[data-table-row]": {
    display: "contents",

    ">div": {
      display: "flex",
      alignItems: "center",
      padding: "20px 8px",
      borderTop: "1px solid #D7D7D7",
    },

    "&:nth-of-type(2n+1)": {
      ">div": {
        backgroundColor: "#F6F6F6",
      },
    },
  },
}));
