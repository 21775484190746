import { Helmet } from "react-helmet-async";
import useUILogic from "./logic";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { REGISTER_PAGE_TITLE } from "lib/page-titles";
import { StyledStepper } from "./styles";
import Spin from "components/spin";
import ConfirmModal from "components/modal/common/confirm-modal";
import { Panel_Routes } from "routes/path";
import Modal from "components/modal";

import RegisterEvaluatorsForm1 from "./components/register-evaluators-form-1";
import RegisterEvaluatorsForm2 from "./components/register-evaluators-form-2";
import RegisterEvaluatorsForm3 from "./components/register-evaluators-form-3";
import RegisterEvaluatorsForm4 from "./components/register-evaluators-form-4";
import RegisterEvaluatorsForm5 from "./components/register-evaluators-form-5";

const steps = [
  "اطلاعات هویتی",
  "اطلاعات محل سکونت",
  "اطلاعات تحصیلی",
  "اطلاعات جهت همکاری",
  "بارگذاری فایل ها",
];

export default function RegisterEvaluatorsFormPage() {
  const {
    step,
    prevStep,
    //
    defaultData,
    isLoadingGet,
    //
    onSubmit,
    isLoadingSubmit,
    openConfirm,
    toggleConfirm,
    onConfirmLastStep,
    //
    navigate,
    openCancel,
    toggleCancel,
    //
    openSuccess,
    toggleSuccess,
  } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{REGISTER_PAGE_TITLE}</title>
      </Helmet>

      <Typography mb={4} fontWeight="bold">
        تکمیل ثبت نام ارزیاب
      </Typography>

      <StyledStepper>
        <Stepper activeStep={step - 1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </StyledStepper>

      <Box
        component={Spin}
        isLoading={isLoadingGet}
        px={{ xs: "0", sm: "100px" }}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: step === 1 ? "contents" : "none" }}>
          <RegisterEvaluatorsForm1
            prevStep={prevStep}
            defaultData={defaultData}
            onSubmit={onSubmit}
            isLoading={isLoadingSubmit}
          />
        </div>

        <div style={{ display: step === 2 ? "contents" : "none" }}>
          <RegisterEvaluatorsForm2
            prevStep={prevStep}
            defaultData={defaultData}
            onSubmit={onSubmit}
            isLoading={isLoadingSubmit}
          />
        </div>

        <div style={{ display: step === 3 ? "contents" : "none" }}>
          <RegisterEvaluatorsForm3
            prevStep={prevStep}
            defaultData={defaultData}
            onSubmit={onSubmit}
            isLoading={isLoadingSubmit}
          />
        </div>

        <div style={{ display: step === 4 ? "contents" : "none" }}>
          <RegisterEvaluatorsForm4
            prevStep={prevStep}
            defaultData={defaultData}
            onSubmit={onSubmit}
            isLoading={isLoadingSubmit}
          />
        </div>

        <div style={{ display: step === 5 ? "contents" : "none" }}>
          <RegisterEvaluatorsForm5
            prevStep={prevStep}
            defaultData={defaultData}
            onSubmit={onSubmit}
            // isLoading={isLoadingSubmit}
          />
        </div>
      </Box>

      <ConfirmModal
        open={!!openConfirm}
        toggle={toggleConfirm}
        isLoading={isLoadingSubmit}
        onConfirm={onConfirmLastStep}
      >
        آیا مطمئنید برای بررسی ارسال شود؟
      </ConfirmModal>

      <ConfirmModal
        open={!!openCancel}
        toggle={toggleCancel}
        onConfirm={() => navigate(Panel_Routes.auth.login.index)}
      >
        آیا از انصراف مطمئنید؟
      </ConfirmModal>

      <Modal
        open={openSuccess}
        toggle={toggleSuccess}
        onClose={() => navigate(Panel_Routes.auth.login.index)}
      >
        <div>
          ثبت نام شما با موفقیت انجام شد، در صورت تایید درخواست، از طریق پیامک
          اطلاع رسانی خواهد شد
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", mt: "24px" }}>
          <Button
            onClick={() => navigate(Panel_Routes.auth.login.index)}
            variant="contained"
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
}
