import { styled } from "@mui/material/styles";

export const StyledStepper = styled("div")(({ theme }) => ({
  marginBottom: 40,

  ".MuiStep-root": {
    paddingLeft: 2,
    paddingRight: 2,
  },

  ".MuiStepLabel-iconContainer": {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: "#ECECEC",
    position: "relative",
    zIndex: 1,

    "&.Mui-completed , &.Mui-active": {
      "&::before": {
        content: "''",
        position: "absolute",
        width: 18,
        height: 18,
        borderRadius: 30,
        backgroundColor: "#647ED1",
        top: 6,
        right: 6,
      },

      "&.Mui-completed": {
        backgroundColor: "#B9FFB8",

        "&::before": {
          backgroundColor: "#0BA108",
        },
      },
    },

    svg: {
      display: "none",
    },
  },

  ".MuiStepLabel-labelContainer": {
    span: {
      fontSize: 12,
      "&.Mui-active": {
        fontWeight: 600,
        color: "#647ED1",
      },
      "&.Mui-completed": {
        color: "#0BA108",
      },
    },
  },

  ".MuiStepConnector-root": {
    left: "50%",
    right: "-50%",
    span: {
      borderTopWidth: 6,
      borderTopColor: "#ECECEC",
    },

    "&.Mui-completed , &.Mui-active": {
      span: {
        borderTopColor: "#B9FFB8",
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    marginBottom: 16,
  },
}));
