import { styled } from "@mui/material/styles";

export const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",

  main: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: theme.mixins.toolbar.height,

    "&>div": {
      width: "100%",
      height: "100%",
      padding: "48px 16px",
    },

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.mixins.toolbar.height_mobile,
      "&>div": {
        padding: "20px 8px",
      },
    },
  },
}));

export const StyledLoading = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1399,
  background: "#00000066",
  backdropFilter: "blur(10px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
