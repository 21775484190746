import { alpha, createTheme } from "@mui/material/styles";

const { palette: MuiPalette } = createTheme();
const { augmentColor } = MuiPalette;

const PRIMARY = {
  main: "#2A3875",
};

const SECONDARY = {
  main: "#647ED1",
};

const WARNING = {
  main: "#FFAB00",
};

const ERROR = {
  main: "#D90000",
};

const SUCCESS = {
  main: "#01BCA3",
};

const GRAY = augmentColor({
  color: { main: "#C4C4C4" },
});

const GRAY2 = augmentColor({
  color: { main: "#555555" },
});

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  warning: WARNING,
  error: ERROR,
  success: SUCCESS,

  divider: alpha("#808080", 0.35),
  text: {
    primary: "#1B1B1B",
    disabled: "#808080",
  },
  background: {
    default: "#EAEAEA",
  },

  gray: GRAY,
  gray2: GRAY2,
};

export default palette;
