import Image from "components/image";
import useUILogic from "./logic";
import { StyledRoot } from "./styles";
import { Toolbar } from "@mui/material";

export default function Header() {
  const { todayDate } = useUILogic();

  return (
    <>
      <StyledRoot>
        <Toolbar>
          <div>
            <div>
              <span>سامانه شبکه مهندسی</span>
              <p>امروز {todayDate}</p>
            </div>
          </div>

          <div>
            <Image
              src="/assets/logo.png"
              sx={{ width: 36, height: 53 }}
            />
          </div>
        </Toolbar>
      </StyledRoot>
    </>
  );
}
