import Table from "components/table";
import useUILogic from "./logic";

export default function EvaluatorsUploads() {
  const { columns, data } = useUILogic();

  return (
    <Table
      columns={columns}
      data={data}
      gridTemplateColumns="56px minmax(125px, 1fr) 56px minmax(125px, 1fr) 80px"
    />
  );
}
