import { useMemo } from "react";
import ActionButton from "../../components/action-button";

export default function useUILogic() {
  const columns = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "نام",
        render: (item) =>
          item.full_name + (item.is_master_of_field ? " (استاد عرصه)" : ""),
      },
      {
        title: "نوع",
        render: (item) => item.type,
      },
      {
        title: "تاریخ درخواست",
        sx: { justifyContent: "center", textAlign: "center" },
        render: (item) => item.requested_datetime ?? "--",
      },
      {
        title: "تاریخ پاسخ",
        sx: { justifyContent: "center", textAlign: "center" },
        render: (item) => item.answered_datetime ?? "--",
      },
      {
        title: "وضعیت",
        render: (item) => item.status,
      },
      {
        title: "",
        render: (item) => (
          <ActionButton
            action={item.action}
            customMessage={`آیا از حذف ${item.full_name} مطمئنید؟`}
          />
        ),
      },
    ],
    []
  );

  return {
    columns,
  };
}
