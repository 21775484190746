import PropTypes from "prop-types";
import Dropzone from "components/dropzone";
import Spin from "components/spin";
import useModal from "hooks/useModal";
import DeleteModal from "components/modal/common/delete-modal";

export default function SingleUploader({ onChange, children, ...other }) {
  const [openDeleteModal, toggleDeleteModal] = useModal();

  return (
    <>
      <Dropzone onError={() => onChange(null)} onSuccess={onChange} {...other}>
        {({ mutation }) => (
          <Spin isLoading={mutation.isLoading}>
            {children({
              error: mutation.error,
              toggleDeleteModal,
            })}
          </Spin>
        )}
      </Dropzone>

      <DeleteModal
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        onDelete={() => {
          onChange(null);
          toggleDeleteModal();
        }}
      >
        <div>Are you sure you want to remove file?</div>
      </DeleteModal>
    </>
  );
}

SingleUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};
