import { styled } from "@mui/material/styles";

export const StyledWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",

  ">div:first-of-type": {
    span: {
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      background: "#ffffff80",
      "&:first-of-type": {
        clipPath: "polygon(0 0, 10% 0, 100% 100%, 0% 100%)",
      },
      "&:last-of-type": {
        clipPath: "polygon(0 0, 5% 0%, 40% 100%, 0% 100%)",
      },
    },
  },
}));

export const StyledContent = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  paddingBottom: 64,

  ">div:first-of-type": {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "[data-cloud]": {
      position: "relative",
      ">:first-of-type": {
        width: 446,
        height: 296,
      },
      ">:last-of-type": {
        width: 112,
        height: 112,
        position: "absolute",
        left: "calc(50% - 56px)",
        top: "calc(55% - 56px)",
      },
      [theme.breakpoints.down("sm")]: {
        ">:first-of-type": {
          width: 268,
          height: 178,
        },
        ">:last-of-type": {
          width: 70,
          height: 70,
          left: "calc(50% - 35px)",
          top: "calc(55% - 35px)",
        },
      },
    },

    h1: {
      color: theme.palette.text.disabled,
      lineHeight: "38px",
      fontWeight: 400,
      fontSize: 32,
      marginTop: 12,
      span: {
        fontWeight: 500,
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "19px",
        fontSize: 16,
      },
    },
    p: {
      color: theme.palette.text.disabled,
      lineHeight: "22px",
      fontWeight: 500,
      fontSize: 18,
      marginTop: 12,

      [theme.breakpoints.down("sm")]: {
        lineHeight: "13px",
        fontSize: 10,
      },
    },
  },

  ">div:last-of-type": {
    lineHeight: "29px",
    fontSize: 24,
    textAlign: "center",
    a: {
      fontWeight: 500,
      color: "inherit",
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      lineHeight: "17px",
      fontSize: 14,
    },
  },
}));
