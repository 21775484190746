import { STORAGE_KEY_USER } from "../config";
import useAxios from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { useGlobalStore } from "store/globalStore";

export default function useLogout() {
  const clearGlobalStore = useGlobalStore((state) => state.clearGlobalStore);
  const navigate = useNavigate();

  const { mutate } = useAxios({
    url: Panel_API_Routes.auth.logout,
    method: "post",
  });

  const logout = async () => {
    clearGlobalStore();
    localStorage.removeItem(STORAGE_KEY_USER);
    navigate(Panel_Routes.auth.login.index);

    try {
      await mutate();
    } catch (error) {}
  };

  return {
    logout,
  };
}
