import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { STORAGE_KEY_USER } from "../../../../config";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { useMemo, useState } from "react";
import useModal from "hooks/useModal";

export default function useUILogic() {
  // const roleOptions = useMemo(
  //   () => [
  //     {
  //       label: "مدیر شبکه",
  //       value: "Network Manager",
  //     },
  //     {
  //       label: "رئیس خوشه",
  //       value: "Industrial Engineer",
  //     },
  //     {
  //       label: "ارزیاب",
  //       value: "Evaluator",
  //     },
  //   ],
  //   []
  // );

  const methods = useForm();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // save formatted number
  const [formatted, setFormatted] = useState();

  // modal confirm
  const [open, toggle] = useModal();

  // submit
  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.login,
    method: "post",
  });

  const onSubmit = () => {
    toggle();
  };

  const onConfirm = async () => {
    const data = methods.getValues();

    try {
      const res = await mutate({ data });

      navigate(
        `${Panel_Routes.auth.otp}?otp_id=${res.data?.data?.otp_id}&mode=login`
      );
    } catch (error) {
      toggle();
    }
  };

  return {
    methods,
    //
    formatted,
    setFormatted,
    //
    open,
    toggle,
    //
    onSubmit,
    onConfirm,
    isLoading,
  };
}
