import { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { StyledDatePicker } from "./styles";

export default function RHKDatePicker({ name, rules, onChange, ...rest }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  const [open, setOpen] = useState(false);

  return (
    <StyledDatePicker
      {...field}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(newValue) => {
        field.onChange(newValue);
        onChange?.(newValue);
      }}
      slotProps={{
        textField: {
          inputRef: ref,
          error: !!error,
          helperText: error ? error?.message || "این مقدار الزامی است" : "",
          readOnly: true,
          placeholder: rest.placeholder,
          onClick: () => !rest.disabled && setOpen(!open),
        },
      }}
      {...rest}
    />
  );
}

RHKDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
