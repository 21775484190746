import { NavLink } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useUILogic from "./logic";
import {
  StyledDrawer,
  StyledList,
  StyledLoading,
  StyledMenuButton,
  StyledNav,
  StyledRole,
  StyledVersion,
} from "./styles";
import SvgBox from "components/svg-box";
import ConfirmModal from "components/modal/common/confirm-modal";
import { DEV_BACKEND_URL } from "routes/path";
import Image from "components/image";

export default function Nav() {
  const {
    sideMenu,
    user,
    //
    wide,
    setWide,
    isDesktop,
    //
    openLogout,
    toggleLogout,
    logout,
    //
    onChangeRole,
    isLoading,
  } = useUILogic();

  const renderContent = (
    <>
      <Stack alignItems={"center"}>
        <Box sx={{ width: 80, height: 80, mt: "20px" }}>
          <Image
            src={DEV_BACKEND_URL + (user?.image || "")}
            ratio="1/1"
            sx={{ borderRadius: 80 }}
          />
        </Box>

        {user?.available_roles?.length > 1 ? (
          <StyledRole>
            <Autocomplete
              size="small"
              disableClearable
              selectOnFocus={false}
              filterSelectedOptions
              options={user?.available_roles}
              value={user?.active_role || null}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              inputValue={user?.active_role?.label}
              onChange={onChangeRole}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          </StyledRole>
        ) : (
          <Typography fontWeight={"bold"} m="36px 0 8px" color="secondary">
            {user?.active_role?.label}
          </Typography>
        )}

        <Typography mb="30px">{user?.full_name}</Typography>
      </Stack>

      <StyledList wide={wide}>
        {sideMenu?.map(({ title, icon, path, disabled }) => (
          <li key={path}>
            <NavLink to={path} data-disabled={disabled}>
              <SvgBox src={`/assets/icons/layout/${icon}.svg`} />
              <span data-title>{title}</span>
            </NavLink>
          </li>
        ))}

        <li>
          <div onClick={() => toggleLogout()}>
            <SvgBox src="/assets/icons/layout/ic_logout.svg" />
            <span data-title>خروج</span>
          </div>
        </li>
      </StyledList>

      <StyledVersion>
        <span>طراحی و اجرا</span>
        <div>اخوان داده سیستم رایان</div>
      </StyledVersion>
    </>
  );

  return (
    <>
      <StyledNav wide={wide}>
        <StyledMenuButton onClick={() => setWide((prev) => !prev)}>
          <SvgBox src="/assets/icons/layout/ic_menu.svg" />
        </StyledMenuButton>

        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}
          open={!isDesktop && wide}
          onClick={() => setWide(false)}
        />
        <StyledDrawer wide={wide}>{renderContent}</StyledDrawer>
      </StyledNav>

      <ConfirmModal
        open={openLogout}
        toggle={toggleLogout}
        onConfirm={() => logout()}
      >
        آیا مطمئن هستید که می خواهید سایت را ترک کنید؟
      </ConfirmModal>

      {isLoading ? (
        <StyledLoading>
          <CircularProgress size={100} />
        </StyledLoading>
      ) : null}
    </>
  );
}
