import { styled } from "@mui/material/styles";

export const StyledCard = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: 10,
  padding: "24px 20px",
  boxShadow: "0px 4px 4px 0px #00000040",
  height: "100%",

  h4: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
  },
}));
