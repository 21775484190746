import Table from "components/table";
import useUILogic from "./logic";
import ViewCard from "../../components/view-card";
import { Box } from "@mui/material";
import ActionButton from "../../components/action-button";

export default function PriceEstimator({ view }) {
  const { columns } = useUILogic();

  return (
    <ViewCard title={view.title}>
      <Table
        columns={columns}
        data={view.data?.invoice}
        gridTemplateColumns="56px 1fr 116px"
        rowKey="label"
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          fontWeight: "bold",
          my: "24px",
        }}
      >
        <div>جمع کل</div>
        <Box
          sx={{
            width: "116px",
            mr: "86px",
          }}
        >
          {view.data?.total_price_label}
        </Box>
      </Box>

      <Box width={"fit-content"}>
        <ActionButton action={view.data?.action} />
      </Box>
    </ViewCard>
  );
}
