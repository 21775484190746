import useAxios from "hooks/useAxios";
import useLogout from "hooks/useLogout";
import { useCallback, useEffect, useState } from "react";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useAxios({
    url: Panel_API_Routes.user.index,
  });
  const { logout } = useLogout();

  const handleLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      await mutate(); // response will set in axiosLib interceptor
    } catch (error) {
      logout();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return {
    isLoading,
  };
}
