import Table from "components/table";
import useUILogic from "./logic";
import Modal from "components/modal";
import { Box, Button, Stack } from "@mui/material";
import { StyledModalTable } from "./styles";

export default function History() {
  const { columns, states_log, openInfoModal, toggleInfoModal } = useUILogic();

  return (
    <>
      <Table
        columns={columns}
        data={states_log}
        gridTemplateColumns="1fr minmax(125px, 1fr) 1fr 1fr"
      />

      <Modal open={!!openInfoModal} toggle={toggleInfoModal}>
        <Stack spacing={1.5} fontSize={17}>
          {Object.entries(openInfoModal).map(([key, value]) => (
            <div key={key}>
              <span>{key}: </span>

              {Array.isArray(value) ? (
                <StyledModalTable>
                  <Table
                    data={value}
                    gridTemplateColumns="1fr 116px"
                    rowKey="label"
                    columns={[
                      { title: "توضیحات", render: (item) => item.label },
                      { title: "مبلغ", render: (item) => item.value },
                    ]}
                  />
                </StyledModalTable>
              ) : (
                <span>{value}</span>
              )}
            </div>
          ))}
        </Stack>

        <Button
          variant="contained"
          color="gray"
          sx={{ mt: "40px" }}
          onClick={() => toggleInfoModal()}
        >
          بستن
        </Button>
      </Modal>
    </>
  );
}
