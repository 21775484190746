export const LOGIN_PAGE_TITLE = "ورود | سایت آزاد";
export const REGISTER_PAGE_TITLE = "ثبت نام | سایت آزاد";

export const ERROR_PAGE_TITLE = "خطا | سایت آزاد";
export const ERROR_401_PAGE_TITLE = "خطای ۴۰۱ | سایت آزاد";
export const ERROR_404_PAGE_TITLE = "۴۰۴ صفحه پیدا نشد | سایت آزاد";
export const ERROR_500_PAGE_TITLE = "۵۰۰ خطای سرور | سایت آزاد";

export const DASHBOARD_PAGE_TITLE = "کارتابل | سایت آزاد";
export const REVIEW_EVALUATION_PAGE_TITLE = "جزئیات درخواست | سایت آزاد";
export const REVIEW_EVALUATOR_PAGE_TITLE = "جزئیات ارزیاب | سایت آزاد";
