export default function MuiAutocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiInputBase-root": {
            "&:has([readonly])": {
              cursor: "pointer",
            },
            input: {
              cursor: "inherit",
            },
            ".MuiAutocomplete-endAdornment": {
              left: 9,
              right: "unset",
            },
          },

          "&.MuiAutocomplete-hasPopupIcon": {
            ".MuiInputBase-root": {
              paddingLeft: 39,
              paddingRight: 9,
            },
          },
          "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
            ".MuiInputBase-root": {
              paddingLeft: 65,
              paddingRight: 9,
            },
          },
        },
      },

      defaultProps: {
        noOptionsText: "موردی وجود ندارد",
        openOnFocus: true,
      },
    },
  };
}
