import { styled } from "@mui/material/styles";

export const StyledUploader = styled("div")(({ theme }) => ({
  borderRadius: 100,
  width: 135,
  height: 135,
  margin: "0 auto 16px",

  ">div": {
    height: "inherit",
    ">div": {
      height: "inherit",

      "[data-profile-upload]": {
        height: "inherit",
        border: "2px solid #647ED1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 100,
        color: "#647ED1",
        cursor: "pointer",

        ".svg-box": {
          width: 48,
          height: 48,
          marginBottom: 8,
        },

        [theme.breakpoints.down("sm")]: {
          ".svg-box": {
            width: 32,
            height: 32,
          },
          div: {
            fontSize: 12,
          },
        },
      },

      "[data-profile-image]": {
        height: "inherit",
        borderRadius: 100,
        cursor: "pointer",

        ">span": {
          borderRadius: "inherit",
        },

        [theme.breakpoints.down("sm")]: {},
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    width: 100,
    height: 100,
    marginBottom: 0,
  },
}));
