import { Helmet } from "react-helmet-async";
import useUILogic from "./logic";
import { Box, CircularProgress } from "@mui/material";
import { LOGIN_PAGE_TITLE } from "lib/page-titles";
import { Link } from "react-router-dom";
import { Panel_Routes } from "routes/path";

export default function LoginTokenPage() {
  const { error } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{LOGIN_PAGE_TITLE}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: "30px 0",
        }}
      >
        {!error ? (
          <CircularProgress size={80} />
        ) : (
          <Link to={Panel_Routes.auth.login.index}>بازگشت به صفحه ورود</Link>
        )}
      </Box>
    </>
  );
}
