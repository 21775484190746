import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  ">.MuiFormLabel-root": {
    cursor: "pointer",
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
  ">.MuiInputBase-root": {
    cursor: "pointer",

    input: {
      cursor: "pointer",
    },

    "&.Mui-disabled": {
      cursor: "default",
      input: {
        cursor: "default",
      },
    },
  },
}));
