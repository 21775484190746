import { Box, Typography } from "@mui/material";
import { StyledTable } from "./styles";
import PropTypes from "prop-types";
import { en2pr } from "lib/helpers";
import Spin from "components/spin";

export default function Table({
  columns,
  data,
  gridTemplateColumns,
  isLoading,
  rowKey = "name",
  ...rest
}) {
  return (
    <Spin isLoading={!!isLoading} {...rest}>
      <StyledTable style={{ gridTemplateColumns }} data-table-root>
        <div data-table-head>
          {columns.map(({ title, sx }) => (
            <Box component="strong" key={title} sx={sx}>
              {title}
            </Box>
          ))}
        </div>

        {data?.length ? (
          data.map((item, i) => (
            <div key={item[rowKey]} data-table-row>
              {columns.map(({ title, sx, render }) => (
                <Box key={title} sx={sx}>
                  {render(item, { row: en2pr(i + 1) })}
                </Box>
              ))}
            </div>
          ))
        ) : (
          <Typography
            variant="body2"
            p={2}
            color="grey.600"
            textAlign="center"
            sx={{ gridColumn: "span 100" }}
          >
            {!isLoading && "موردی برای نمایش وجود ندارد"}
          </Typography>
        )}
      </StyledTable>
    </Spin>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  gridTemplateColumns: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
