import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function useUILogic({ defaultData, onSubmit }) {
  const methods = useForm();

  useEffect(() => {
    if (defaultData) {
      methods.reset({
        id_card_file: defaultData.id_card_file
          ? { data: { url: defaultData.id_card_file } }
          : null,
        resume_file: defaultData.resume_file
          ? { data: { url: defaultData.resume_file } }
          : null,
        other_document_file: defaultData.other_document_file
          ? { data: { url: defaultData.other_document_file } }
          : null,
      });
    }
  }, [defaultData]);

  const onNext = (params) => {
    onSubmit({
      ...params,
      id_card_file: params.id_card_file?.data?.url,
      resume_file: params.resume_file?.data?.url,
      other_document_file: params.other_document_file?.data?.url,
      is_complete: true,
    });
  };

  const successMessage = () => {
    enqueueSnackbar("فایل با موفقیت بارگذاری شد", { variant: "success" });
  };

  return {
    methods,
    onNext,
    successMessage,
  };
}
