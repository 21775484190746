import RHKTextField from "components/forms/rhk-text-field";
import useUILogic from "./logic";
import { Button, Grid } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import RHKSelect from "components/forms/rhk-select";
import RHKSelectEvaluationFields from "components/forms/rhk-select-evaluation-fields";

export default function RegisterEvaluatorsForm4({
  prevStep,
  defaultData,
  onSubmit,
  isLoading,
}) {
  const {
    methods,
    onNext,
    isMemberOfAzadOptions,
    positionAtAzadOptions,
    is_member_of_azad,
  } = useUILogic({ defaultData, onSubmit });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onNext)}
        noValidate
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexDirection="column" rowSpacing={4}>
          <Grid item>
            <RHKTextField
              name="job_title"
              label="شغل *"
              placeholder=""
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <RHKSelect
              name="is_member_of_azad"
              label="عضویت در دانشگاه آزاد  *"
              rules={{ required: true }}
              options={isMemberOfAzadOptions}
              placeholder={"انتخاب کنید"}
              disabled={isLoading}
            />
          </Grid>

          <Grid item>
            <RHKSelect
              name="position_at_azad"
              label="سمت در دانشگاه آزاد  *"
              rules={{ required: is_member_of_azad }}
              disabled={isLoading || !is_member_of_azad}
              options={positionAtAzadOptions}
              placeholder={"انتخاب کنید"}
            />
          </Grid>

          <Grid item>
            <RHKTextField
              name="azad_unit_name"
              label="نام واحد دانشگاه  *"
              placeholder=""
              rules={{ required: is_member_of_azad }}
              disabled={isLoading || !is_member_of_azad}
            />
          </Grid>

          <Grid item>
            <RHKSelectEvaluationFields
              name="evaluation_field"
              label="حوزه ارزیابی  *"
              rules={{ required: true }}
              disabled={isLoading}
              placeholder={"انتخاب کنید"}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="space-around" mt={"auto"} pt={3}>
          <Grid item>
            <Button
              variant="contained"
              color="gray"
              onClick={prevStep}
              disabled={isLoading}
            >
              مرحله قبل
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
            >
              مرحله بعد
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
