import Table from "components/table";
import useUILogic from "./logic";
import AddEvaluatorModal from "./components/add-evaluator-modal";
import ViewCard from "../../components/view-card";

export default function EvaluatorsManager({ view }) {
  const { columns } = useUILogic();

  return (
    <ViewCard title={view.title}>
      <Table
        columns={columns}
        data={view.data}
        gridTemplateColumns="56px minmax(125px, 1fr) 1fr 1fr 1fr minmax(125px, 1fr) 56px"
        rowKey="full_name"
      />

      <AddEvaluatorModal isListEmpty={!view.data?.length} />
    </ViewCard>
  );
}
