import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";
import { StyledSpin } from "./styles";

export default function Spin({ children, isLoading, size = 40, ...rest }) {
  return (
    <StyledSpin {...rest}>
      <Backdrop open={isLoading}>
        {isLoading && <CircularProgress color="inherit" size={size} />}
      </Backdrop>
      {children}
    </StyledSpin>
  );
}

Spin.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  size: PropTypes.number,
};
