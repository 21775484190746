import { styled } from "@mui/material/styles";

export const StyledLoading = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0px 4px 4px 0px #00000040",
  borderRadius: 10,
  padding: "70px 30px",
  display: "flex",
  justifyContent: "center",
}));

export const StyledNotFound = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0px 4px 4px 0px #00000040",
  borderRadius: 10,
  padding: "70px 30px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  ">div": {
    marginBottom: 48,
    fontSize: 24,
  },
}));

export const StyledContent = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "0px 4px 4px 0px #00000040",
  borderRadius: 10,
  padding: "55px 30px 30px",

  "[data-profile-image]": {
    width: 124,
    height: 124,
    margin: "0 auto 60px",
    borderRadius: 100,
    ">span": {
      borderRadius: "inherit",
    },

    [theme.breakpoints.down("md")]: {
      width: 94,
      height: 94,
      margin: "0 auto 30px",
    },
  },

  ".MuiGrid-item": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    ">div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "19px 14px",

      "&:nth-of-type(2n+1)": {
        backgroundColor: "#F8F8F8",
      },

      ">div": {
        fontWeight: 600,
        flex: 1,
      },
      ">span": {
        textAlign: "center",
        flex: 1,
        a: {
          textDecoration: "underline",
        },
      },

      "&[data-column]": {
        flexDirection: "column",
        alignItems: "flex-start",
        ">span": {
          textAlign: "right",
          marginTop: 7,
        },
      },
    },
  },

  ">div:last-of-type": {
    marginTop: 100,
    display: "flex",
    borderRadius: 5,
    button: {
      padding: "12px 36px",
      width: 150,
      "&:last-of-type": {
        marginRight: 24,
      },
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 50,
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      button: {
        width: "100%",
        "&:last-of-type": {
          marginRight: 0,
          marginTop: 12,
        },
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    padding: "16px 12px",
  },
}));
