import useUILogic from "./logic";
import Table from "components/table";
import { LoadingButton } from "@mui/lab";
import Modal from "components/modal";
import { Box, Button, Grid, Typography } from "@mui/material";
import { StyledContent } from "./styles";

export default function ResultMetaModal({ open, toggle, uploadData }) {
  const {
    isLoading,
    onConfirm,
    //
    columns,
  } = useUILogic({ toggle, uploadData });

  return (
    <Modal open={open} toggle={toggle}>
      <Typography
        variant="h6"
        fontWeight="600"
        textAlign="center"
        mb={3}
        color="primary"
      >
        نتایج ارزیابی
      </Typography>

      <StyledContent>
        <Table
          columns={columns}
          data={uploadData?.meta_data}
          gridTemplateColumns="56px 3fr 1fr"
          rowKey="id"
        />
      </StyledContent>

      <Box mt={4} />
      <Grid
        container
        spacing={2}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => onConfirm()}
            sx={{ minWidth: 220 }}
          >
            تایید و ثبت
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button variant="contained" color="gray" onClick={() => toggle()}>
            لغو
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
