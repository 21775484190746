import { styled } from "@mui/material/styles";

export const StyledUploader = styled("div")(({ theme }) => ({
  ".MuiInputBase-root": {
    input: {
      cursor: "pointer",
    },
    "a+input": {
      cursor: "default",
    },

    a: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    },

    button: {
      color: "black",
    },
  },
}));
