import { TextField } from "@mui/material";
import { en2pr, pr2en } from "lib/helpers";
import { NumberFormatBase, useNumericFormat } from "react-number-format";

export default function CustomNumeralNumericFormat(props) {
  const { format, removeFormatting } = useNumericFormat({
    thousandSeparator: ",",
  });

  const _format = (val) => {
    const _val = format(val);
    return en2pr(_val);
  };

  const _removeFormatting = (val) => {
    const _val = pr2en(val);
    return removeFormatting(_val);
  };

  return (
    <NumberFormatBase
      format={_format}
      removeFormatting={_removeFormatting}
      customInput={TextField}
      {...props}
    />
  );
}
