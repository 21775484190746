import useUILogic from "./logic";
import ViewCard from "../../components/view-card";
import { StyledBox, StyledDatePicker } from "./styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

export default function DateCoordinator({ view }) {
  const { open, setOpen, dateTime, setDateTime, isLoading, onConfirm } =
    useUILogic({
      view,
    });

  return (
    <ViewCard title={view?.title}>
      <StyledBox>
        <div>اطلاعات مشتری</div>
        <div>
          <div>
            <span>نام : &nbsp; &nbsp; </span>&nbsp;
            <span>{view.data?.customer_info?.full_name}</span>
          </div>
          <div>
            <span>شماره تماس : &nbsp; &nbsp; </span>
            <span>{view.data?.customer_info?.phone}</span>
          </div>
        </div>
      </StyledBox>

      <StyledDatePicker>
        <DateTimePicker
          label="انتخاب تاریخ و ساعت"
          ampm={false}
          timeSteps={{ minutes: 15 }}
          format="yyyy/MMM/dd  HH:mm"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(value) => setDateTime(value)}
          slotProps={{
            textField: {
              readOnly: true,
              placeholder: " ",
              onClick: () => setOpen(!open),
            },
          }}
        />
      </StyledDatePicker>

      <LoadingButton
        variant="contained"
        onClick={() => onConfirm()}
        loading={isLoading}
        disabled={!dateTime}
      >
        {view.data?.action?.title}
      </LoadingButton>
    </ViewCard>
  );
}
