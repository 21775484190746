import { useMemo } from "react";
import { useRequestContext } from "../../request-context";

export default function useUILogic() {
  const { requestData } = useRequestContext();

  const data = requestData?.views?.find(
    ({ tag }) => tag === "ir_detail_view_evaluators_uploads"
  )?.data;

  const columns = useMemo(
    () => [
      {
        title: "ردیف",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "نام",
        sx: { justifyContent: "center" },
        render: (item) => item.full_name,
      },
      {
        title: "نوع",
        sx: { justifyContent: "center" },
        render: (item) => item.role_type,
      },
      {
        title: "زمان بارگذاری",
        sx: { justifyContent: "center" },
        render: (item) => item.upload_timestamp ?? "--",
      },
      {
        title: "عملیات",
        sx: { justifyContent: "center", a: { textDecoration: "underline" } },
        render: (item) =>
          item.url ? (
            <a href={item.url} target="_blank">
              دانلود
            </a>
          ) : (
            "--"
          ),
      },
    ],
    []
  );

  return {
    data,
    columns,
  };
}
