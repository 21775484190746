import Table from "components/table";
import useUILogic from "./logic";
import ViewCard from "../../components/view-card";

export default function AssignHeadEvaluator({ view }) {
  const { columns } = useUILogic();

  return (
    <ViewCard title={view.title}>
      <Table
        columns={columns}
        data={view.data}
        gridTemplateColumns="56px 1fr 120px 120px 116px"
      />
    </ViewCard>
  );
}
