import { Chip } from "@mui/material";
import BlinkingBadge from "components/blinking-badge";
import useAxios from "hooks/useAxios";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { useGlobalStore } from "store/globalStore";

export default function useUILogic() {
  const user = useGlobalStore((state) => state.user);

  // registrants
  // const { isLoading: isLoadingRegistrants, data: dataRegistrants } = useAxios({
  //   url: Panel_API_Routes.auth.registrants,
  //   loadOnStart: user?.role === "Industrial Engineer",
  // });

  // const columnsRegistrants = useMemo(
  //   () => [
  //     {
  //       title: "ردیف",
  //       sx: { justifyContent: "center" },
  //       render: (item, { row }) => row,
  //     },
  //     {
  //       title: "نام و نام خانوادگی",
  //       sx: { a: { textDecoration: "underline" } },
  //       render: (item) => (
  //         <Link to={Panel_Routes.dashboard.registrants(item.id)}>
  //           {item.full_name}
  //         </Link>
  //       ),
  //     },
  //     {
  //       title: "نقش",
  //       sx: { justifyContent: "center" },
  //       render: (item) => item.type,
  //     },
  //     {
  //       title: "تاریخ",
  //       sx: { justifyContent: "center" },
  //       render: (item) => item.modified,
  //     },
  //     {
  //       title: "وضعیت",
  //       render: (item) => (
  //         <Chip
  //           color={item.is_complete ? "success" : "error"}
  //           label={item.is_complete ? "تکمیل ثبت نام" : "ثبت نام ناقص"}
  //         />
  //       ),
  //     },
  //   ],
  //   []
  // );

  // evaluations
  const { isLoading: isLoadingEvaluations, data: dataEvaluations } = useAxios({
    url: Panel_API_Routes.erm.requests,
    loadOnStart: true,
  });

  const columnsEvaluations = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "",
        sx: { justifyContent: "center" },
        render: (item) => !!item.is_updated && <BlinkingBadge size={15} />,
      },
      {
        title: "عنوان",
        sx: { a: { textDecoration: "underline" } },
        render: (item) => (
          <Link to={Panel_Routes.dashboard.evaluation(item.name)}>
            {item.title}
          </Link>
        ),
      },
      {
        title: "آخرین تغییر",
        sx: { justifyContent: "center" },
        render: (item) => item.last_change,
      },
      {
        title: "آخرین وضعیت",
        render: (item) => item.last_state,
      },
    ],
    []
  );

  // inspections
  const { isLoading: isLoadingInspections, data: dataInspections } = useAxios({
    url: Panel_API_Routes.irm.requests,
    loadOnStart: user?.active_role?.value !== "Evaluator",
  });

  const columnsInspections = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "",
        sx: { justifyContent: "center" },
        render: (item) => !!item.is_updated && <BlinkingBadge size={15} />,
      },
      {
        title: "عنوان",
        sx: { a: { textDecoration: "underline" } },
        render: (item) => <Link to={"#"}>{item.title}</Link>,
      },
      {
        title: "آخرین تغییر",
        sx: { justifyContent: "center" },
        render: (item) => item.last_change,
      },
      {
        title: "آخرین وضعیت",
        render: (item) => item.last_state,
      },
    ],
    []
  );

  return {
    user,
    //
    dataEvaluations,
    columnsEvaluations,
    isLoadingEvaluations,
    //
    dataInspections,
    columnsInspections,
    isLoadingInspections,
  };
}
