import { Helmet } from "react-helmet-async";
import useUILogic from "./logic";
import { Button, Grid, TextField, Typography } from "@mui/material";
import ConfirmModal from "components/modal/common/confirm-modal";
import { REGISTER_PAGE_TITLE } from "lib/page-titles";
import { Link } from "react-router-dom";
import { Panel_Routes } from "routes/path";
import RHKCustomPatternFormat from "components/forms/rhk-custom-pattern-format";
import { FormProvider } from "react-hook-form";
import { en2pr } from "lib/helpers";

export default function RegisterEvaluatorsPage() {
  const {
    methods,
    //
    formatted,
    setFormatted,
    //
    open,
    toggle,
    //
    onSubmit,
    onConfirm,
    isLoading,
  } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{REGISTER_PAGE_TITLE}</title>
      </Helmet>

      <Typography mb={4}>ثبت نام ارزیاب</Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container flexDirection="column" rowSpacing={4}>
            <Grid item>
              <RHKCustomPatternFormat
                name="mobile"
                format="#### ### ####"
                label="شماره همراه"
                placeholder="۰۹×× ××× ××××"
                disabled={isLoading}
                rules={{
                  required: true,
                  pattern: {
                    value: /^09[0-9]{9}/,
                    message: "فرمت شماره همراه اشتباه است",
                  },
                }}
                onChange={(e) => setFormatted(e.formattedValue)}
              />
            </Grid>

            <Grid item>
              <Button variant="contained" sx={{ width: 129 }} type="submit">
                ثبت نام
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <Typography variant="body2" mt={5} mb={4}>
        حساب کاربری دارید ؟{" "}
        <Link to={Panel_Routes.auth.login.index}>ورود به شبکه مهندسی</Link>
      </Typography>

      <ConfirmModal
        open={open}
        toggle={toggle}
        onConfirm={onConfirm}
        isLoading={isLoading}
      >
        شماره همراه{" "}
        <div style={{ direction: "ltr", display: "inline" }}>
          {en2pr(methods.watch("mobile"))}
        </div>{" "}
        را تایید می کنید ؟
      </ConfirmModal>
    </>
  );
}
