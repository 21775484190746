import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Box, Typography } from "@mui/material";
import useLocalStorage from "hooks/useLocalStorage";
import { STORAGE_KEY_USER } from "../../config";
import { StyledAuth } from "./styles";
import Image from "components/image";
import { useMemo } from "react";

export default function AuthLayout() {
  let [searchParams] = useSearchParams();
  const [user] = useLocalStorage(STORAGE_KEY_USER);

  const location = useLocation();
  const isFormPage = useMemo(
    () => !location.pathname.includes("/auth/register/evaluators/"),
    [location.pathname]
  );

  if (user) {
    return <Navigate to={searchParams.get("next") || "/"} />;
  }

  return (
    <main>
      <StyledAuth isFormPage={isFormPage}>
        <div>
          <Box mb={3}>
            {isFormPage ? (
              <Box mt={5}>
                <Image
                  src="/assets/logo.png"
                  sx={{ width: 70, height: 104, margin: "0 auto" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  bgcolor: "primary.main",
                  borderRadius: { md: "16px 16px 0 0" },
                  height: "69px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src="/assets/images/logo-2.png"
                  sx={{ width: 36, height: 53 }}
                />
              </Box>
            )}
          </Box>

          <div>
            <Outlet />
          </div>

          <Typography variant="caption" color="text.disabled" mt={4} mb={4}>
            کلیه حقوق برای دانشگاه آزاد محفوظ می باشد
          </Typography>
        </div>
      </StyledAuth>
    </main>
  );
}
