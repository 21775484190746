import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledNav = styled("nav")(({ theme, wide }) => ({
  width: wide ? theme.mixins.navbar.width : 0,
  transition: theme.transitions.create("width"),

  [theme.breakpoints.down("lg")]: {
    width: 0,
  },
}));

export const StyledDrawer = styled("div")(({ theme, wide }) => ({
  position: "fixed",
  top: theme.mixins.toolbar.height,
  bottom: 0,
  boxShadow: "0px 4px 4px 0px #00000040",
  backgroundColor: "white",
  padding: "32px 0",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: wide ? theme.mixins.navbar.width : 0,
  zIndex: theme.zIndex.appBar,
  transition: theme.transitions.create("width"),

  [theme.breakpoints.down("lg")]: {
    width: wide ? theme.mixins.navbar.width_mobile : 0,
  },
}));

export const StyledMenuButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  top: 12,
  right: 24,
  zIndex: theme.zIndex.appBar,
  color: "white",
  ".svg-box": {
    width: 32,
    height: 32,
  },

  [theme.breakpoints.down("md")]: {
    right: 16,
  },
}));

export const StyledRole = styled("div")(({ theme, wide }) => ({
  padding: "0 25px",
  width: "100%",
  margin: "16px 0",
  height: "44px",

  ".MuiAutocomplete-root": {
    height: "100%",
    ".MuiFormControl-root": {
      height: "100%",

      input: {
        textAlign: "center",
        color: theme.palette.secondary.main,
        fontWeight: "bold",
      },
    },
  },
}));

export const StyledList = styled("ul")(({ theme, wide }) => ({
  flex: 1,
  li: {
    ">a , >div": {
      padding: "12px 32px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: theme.transitions.create("all"),

      ".svg-box": {
        minWidth: 32,
        height: 32,
        marginLeft: 8,
      },

      "&.active": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
      },

      "&[data-disabled=true]": {
        color: theme.palette.text.disabled,
        pointerEvents: "none",
      },
    },
  },
}));

export const StyledVersion = styled("div")(({ theme, wide }) => ({
  textAlign: "center",
  fontSize: 12,
  color: "#7B7B7B",
  div: {
    fontWeight: 700,
    marginTop: 6,
  },
}));

export const StyledLoading = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1399,
  background: "#00000066",
  backdropFilter: "blur(10px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
