import { Typography } from "@mui/material";
import Spin from "components/spin";
import SvgBox from "components/svg-box";
import useAxios from "hooks/useAxios";
import { useEffect, useMemo, useState } from "react";
import { Panel_API_Routes } from "routes/path";
import { useParams } from "react-router-dom";
import { useRequestContext } from "../../../../request-context";
import { enqueueSnackbar } from "notistack";
import useModal from "hooks/useModal";

export default function useUILogic() {
  const { id } = useParams();
  const { setRequestData } = useRequestContext();

  // modal
  const [open, toggle] = useModal();

  // get
  const {
    isLoading: isLoadingGet,
    data,
    mutate: mutateGet,
  } = useAxios({
    url: Panel_API_Routes.resources.evaluators,
  });

  useEffect(() => {
    if (open) handleGet();
  }, [open]);

  const handleGet = async () => {
    try {
      await mutateGet();
    } catch (error) {}
  };

  // add
  const [addingName, setAddingName] = useState(null);

  const { mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.erm.request(id),
    method: "put",
  });

  useEffect(() => {
    if (addingName) handleAdd();
  }, [addingName]);

  const handleAdd = async () => {
    try {
      const data = {
        tag: "er_action_assign_evaluator",
        user_doc_name: addingName,
      };
      const res = await mutateAdd({ data });
      toggle();
      setRequestData(res.data?.data);
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
    } catch (error) {}
    setAddingName(null);
  };

  // table
  const columns = useMemo(
    () => [
      {
        title: "#",
        render: (item, { row }) => row,
      },
      {
        title: "نام",
        render: (item) => item.full_name,
      },
      {
        title: "استاد عرصه",
        sx: { justifyContent: "center", ".svg-box": { color: "#438006" } },
        render: (item) =>
          item.is_master_of_field && (
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 33, height: 33 }}
            />
          ),
      },
      {
        title: "نوع",
        render: (item) => item.type,
      },
      {
        title: "عملیات",
        render: (item) => (
          <Spin isLoading={addingName === item.user_doc_name} size={20}>
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#1E83BB",
              }}
              onClick={() => setAddingName(item.user_doc_name)}
            >
              اضافه کردن
            </Typography>
          </Spin>
        ),
      },
    ],
    [addingName]
  );

  return {
    open,
    toggle,
    //
    data,
    isLoadingGet,
    //
    columns,
  };
}
