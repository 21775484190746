export default function MuiTextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          ".MuiFormLabel-root": {
            backgroundColor: "white",
            padding: "0 8px",
            left: "unset",
            right: 0,
            transform: "translate(-9px, -9px) scale(1)",
            fontSize: 12,
            "&.Mui-focused": {
              color: theme.palette.secondary.main,
            },
            "&.Mui-error": {
              color: theme.palette.error.main,
            },
          },
          ".MuiInputBase-root": {
            height: "inherit",
            input: {
              "&::placeholder": {
                color: theme.palette.text.disabled,
              },
              "&[type=email]": {
                direction: "ltr",
              },
            },
            ">fieldset": {
              borderRadius: 5,
              top: 0,
              legend: {
                height: 0,
                width: 0,
              },
            },
            "&.Mui-focused": {
              ">fieldset": {
                borderWidth: 1,
                borderColor: theme.palette.secondary.main,
              },
            },
            "&.Mui-error": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
          },
          ".MuiFormHelperText-root": {
            textAlign: "right",
          },

          "&[inputmode=numeric]": {
            direction: "ltr",
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
  };
}
