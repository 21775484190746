import useAxios from "hooks/useAxios";
import { useMemo } from "react";
import { Panel_API_Routes } from "routes/path";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { en2pr } from "lib/helpers";
import { useRequestContext } from "../../../../request-context";

export default function useUILogic({ toggle, uploadData }) {
  const { id } = useParams();
  const { setRequestData } = useRequestContext();

  // confirm
  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.erm.request(id),
    method: "put",
  });

  const onConfirm = async () => {
    const data = {
      tag: "er_action_upload_result",
      file_link: uploadData?.url,
      results_data: uploadData?.meta_data,
    };

    try {
      const res = await mutate({ data });
      setRequestData(res.data?.data);

      toggle();
      enqueueSnackbar("عملیات موفقیت آمیز بود", { variant: "success" });
    } catch (error) {}
  };

  // table
  const columns = useMemo(
    () => [
      {
        title: "#",
        sx: { justifyContent: "center" },
        render: (item, { row }) => row,
      },
      {
        title: "عنوان",
        render: (item) => item.label,
      },
      {
        title: "امتیاز",
        render: (item) => en2pr(item.value) || "N/A",
      },
    ],
    []
  );

  return {
    isLoading,
    onConfirm,
    //
    columns,
  };
}
