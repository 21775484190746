import MuiAutocomplete from "./Autocomplete";
import MuiButton from "./Button";
import MuiChip from "./Chip";
import MuiTextField from "./TextField";

export default function ComponentsOverrides(theme) {
  return Object.assign(
    MuiAutocomplete(theme),
    MuiButton(theme),
    MuiTextField(theme),
    MuiChip(theme)
  );
}
