import { format } from "date-fns-jalali";
import { en2pr } from "lib/helpers";
import { useMemo } from "react";

export default function useUILogic() {
  const todayDate = useMemo(
    () => en2pr(format(new Date(), "E d MMMM yyyy")),
    []
  );

  return {
    todayDate,
  };
}
