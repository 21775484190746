import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import Modal from "components/modal";
import SvgBox from "components/svg-box";
import PropTypes from "prop-types";

export default function ConfirmModal({
  toggle,
  onConfirm,
  isLoading,
  children,
  ...rest
}) {
  return (
    <Modal toggle={toggle} {...rest}>
      <Box
        display="flex"
        justifyContent="center"
        mb={4}
        color={(theme) => theme.palette.primary.main}
      >
        <SvgBox
          src="/assets/icons/common/ic_question.svg"
          sx={{ width: 96, height: 96 }}
        />
      </Box>

      {children}

      <Box mt={6} />
      <Grid
        container
        spacing={2}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        <Grid item>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => onConfirm()}
          >
            تایید
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button variant="contained" color="gray" onClick={() => toggle()}>
            لغو
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
